<template lang="pug">
  v-app-bar(color="primary" dark dense elevate-on-scroll app)
    middleware-holder(v-slot="{ isLoading }")
      v-progress-linear(
        indeterminate
        color="white"
        absolute
        top
        :active="isLoading"
        :indeterminate="isLoading"
      )
        
    div
      v-list-item.mr-10
        v-img(:src="logoSrc" max-height="40" max-width="140" contain)
    v-tabs
      v-tab(
          v-for="item in items"
          :to="{name: item.name}"
          :key="item.name"
        ) {{item.title}}
    v-spacer

    div
      v-list-item(
        @click="signOut"
      ) Выход

</template>

<script>
import { mapActions } from 'vuex';
import { Logo } from '@/assets';
import { MODULES } from '@/utils/constants';

export default {
  data() {
    return {
      logoSrc: Logo,
    };
  },
  computed: {
    items() {
      const hiddenModules = process.env.VUE_APP_HIDE_MODULES?.split(',') || [];
      return MODULES.filter(
        item => this.$can(item.right) && !hiddenModules.includes(item.name),
      );
    },
  },
  methods: {
    ...mapActions('AUTH', ['signOut']),
  },
};
</script>
