export default async function ({ to, $store, next, $notify }) {
  const ids = to.query.ids.split(',');
  const versionId = to.query.verId;

  await $store
    .dispatch('TERMINAL_UPDATE/getDataForFormEdit', { ids, versionId })
    .catch(() => {
      $notify({
        group: 'error',
        type: 'error',
        title: `Произошла ошибка загрузки конфигурации терминала. Попробуйте позже.`,
        text: '',
      });
    });

  next();
}
