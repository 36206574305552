import api from '@/api/config';
import { auth } from '@/utils/middlewares';
import terminalList from '@/middleware/terminal-list';
import terminalDictionary from '@/middleware/terminal-dictionary';
import terminalEdit from '@/middleware/terminal-edit';
import terminalCreate from '@/middleware/terminal-create';

export default {
  path: '/terminals',
  name: 'terminals',
  component: () => import('@/views/terminals/index.vue'),
  redirect: { name: 'terminals_list' },
  meta: {
    middleware: [auth('login', api.check)],
  },
  children: [
    {
      path: 'list',
      name: 'terminals_list',
      component: () => import('@/views/terminals/list.vue'),
      meta: {
        middleware: [
          auth('login', api.check),
          terminalList,
          terminalDictionary,
        ],
      },
    },
    {
      path: 'edit',
      name: 'edit',
      component: () => import('@/views/terminals/edit.vue'),
      meta: {
        middleware: [auth('login', api.check), terminalEdit],
      },
    },
    {
      path: 'create',
      name: 'create',
      component: () => import('@/views/terminals/create.vue'),
      meta: {
        middleware: [auth('login', api.check), terminalCreate],
      },
    },
  ],
};
