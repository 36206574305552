export default async function ({ from, to, $store, next, $notify }) {
  const id = Number(to.params.id);

  if (!id) {
    return next();
  }

  await $store.dispatch('EMPLOYEE/fetchEmployee', id).catch(() => {
    $notify({
      group: 'error',
      type: 'error',
      title: `Произошла ошибка загрузки работника. Попробуйте позже.`,
      text: '',
    });

    return next({ name: from.name });
  });

  return next();
}
