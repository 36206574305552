const state = {
  queryParams: {
    version: null,
    tags: [],
    orgId: null,
    openpoints: false,
  },
};

const getters = {
  orgId: state => state.queryParams.orgId,

  version: state => state.queryParams.version,

  tags: state => state.queryParams.tags,

  openpoints: state => state.queryParams.openpoints,

  query: state => {
    const result = {};

    Object.entries(state.queryParams).forEach(item => {
      if (
        item[1] !== null ||
        (Array.isArray(item[1]) && item[1].length !== 0)
      ) {
        result[item[0]] = item[1];
      }

      if (
        item[0] === 'tags' &&
        Array.isArray(item[1]) &&
        item[1].length !== 0
      ) {
        result[item[0]] = item[1].join(',');
      }

      if (item[0] === 'orgId' && item[1] === 'openpoints') {
        result[item[0]] = null;
      }
    });

    return result;
  },
};

const mutations = {
  setFilterOrgId(state, id) {
    state.queryParams.orgId = id;
  },

  setFilterVersion(state, version) {
    state.queryParams.version = version;
  },

  setFilterTags(state, tags) {
    state.queryParams.tags = tags;
  },

  setFilterOpenpoints(state, openpoints) {
    state.queryParams.openpoints = openpoints;

    if (openpoints) {
      state.queryParams.orgId = 'openpoints';
    } else {
      state.queryParams.orgId = null;
    }
  },
};

const actions = {
  async reset({ commit, dispatch }) {
    commit('setFilterOrgId', null);
    commit('setFilterVersion', null);
    commit('setFilterTags', []);
    // commit('setFilterOpenpoints', false);
    // await dispatch(`TERMINALS_LIST/${QUERY_LIST}`);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
