import { Logging, Cameras, CameraVideoTiming } from '../components';
import { wrapper } from './utils';

export default [
  {
    name: 'Logging',
    type: 'control',
    pattern: [
      x => x.type === 'object',
      x => typeof x.title === 'string',
      x =>
        typeof x.additionalProperties === 'boolean' && x.additionalProperties,
      x => Array.isArray(x.required),
    ],
    required: [
      x => x.required.includes('appenders'),
      x => x.required.includes('loggers'),
    ],
    valuePath: 'properties',
    component({ $h, $schema, $value, $valid, $errors }) {
      const value = $value();
      let defaultValue = null;

      if (
        value &&
        value.appenders &&
        value.appenders[0] &&
        value.appenders[0].thresholdLevel
      ) {
        defaultValue = value.appenders[0].thresholdLevel;
      }

      $value(undefined);

      const content = $h(Logging, {
        props: {
          label: $schema.title,
          value: defaultValue,
          items: $schema.properties,
          errorMessages: $errors(),
          error: $errors().length !== 0,
        },
        on: {
          change: value => {
            $valid(value);
            $value(value);
          },
        },
      });

      return wrapper($h, content);
    },
    validate({ $schema, $parentSchema, $name }) {
      return [
        {
          properties: {
            value: {
              type: 'object',
              properties: {
                appenders: { type: 'array' },
                loggers: { type: 'object' },
              },
            },
          },
          errorMessage: 'Обязательное заполнение',
        },
      ];
    },
  },

  {
    name: 'Cameras',
    type: 'control',
    pattern: [
      x => x.type === 'array',
      x => typeof x.title === 'string',
      x => typeof x.additionalItems === 'boolean' && x.additionalItems,
      x => Array.isArray(x.default),
    ],
    required: [
      x =>
        x.items &&
        Array.isArray(x.items.required) &&
        x.items.required.includes('label'),
      x =>
        x.items &&
        Array.isArray(x.items.required) &&
        x.items.required.includes('name'),
      x =>
        x.items &&
        Array.isArray(x.items.required) &&
        x.items.required.includes('in'),
      x =>
        x.items &&
        Array.isArray(x.items.required) &&
        x.items.required.includes('fmt'),
    ],
    valuePath: 'properties',
    component({ $h, $schema, $value, $valid, $errors }) {
      const schema = { ...$schema };
      schema.default = [...$value()];

      return $h(Cameras, {
        props: {
          schema,
        },
        on: {
          input: value => $value(value),
        },
      });
    },
  },

  {
    name: 'CameraVideoTiming',
    type: 'control',
    pattern: [
      x => x.type === 'array',
      x => typeof x.title === 'string',
      x => typeof x.description === 'string',
      x => typeof x.additionalItems === 'boolean' && x.additionalItems,
      x => typeof x.items.additionalProperties && x.items.additionalProperties,
      x => Array.isArray(x.default),
    ],
    required: [
      x =>
        x.items &&
        Array.isArray(x.items.required) &&
        x.items.required.includes('cameraName'),
      x =>
        x.items &&
        Array.isArray(x.items.required) &&
        x.items.required.includes('seconds'),
    ],
    valuePath: 'properties',
    component({ $h, $schema, $value, $valid, $errors }) {
      const schema = { ...$schema };
      schema.default = [...$value()];

      return $h(CameraVideoTiming, {
        props: {
          schema,
        },
        on: {
          input: value => $value(value),
        },
      });
    },
  },
];
