import api from '@/api/config';
import { auth } from '@/utils/middlewares';
import notifications from '@/middleware/notifications';
import notificationsBindings from '@/middleware/notifications-bindings';

export default {
  path: '/notifications',
  name: 'notifications',
  component: () => import('@/views/admin-notifications/index.vue'),
  redirect: { name: 'list_recipients' },
  children: [
    {
      path: 'list',
      name: 'list_recipients',
      component: () =>
        import('@/views/admin-notifications/recipients/list.vue'),
      meta: {
        middleware: [auth('login', api.check), notifications],
      },
    },
    {
      path: 'table',
      name: 'group_recipients',
      component: () =>
        import('@/views/admin-notifications/GroupRecipients.vue'),
      meta: {
        middleware: [auth('login', api.check)],
      },
    },
    {
      path: 'binding',
      name: 'binding_group',
      component: () =>
        import('@/views/admin-notifications/bindings/Bindings.vue'),
      meta: {
        middleware: [auth('login', api.check), notificationsBindings],
      },
    },
  ],
};
