import api from '@/api/config';
import { auth } from '@/utils/middlewares';

export default {
  path: '/accounts',
  name: 'accounts',
  component: () => import('@/views/accounts/index.vue'),
  redirect: { name: 'accounts:list' },
  children: [
    {
      path: '',
      name: 'accounts:list',
      component: () => import('@/views/accounts/list.vue'),
      meta: {
        middleware: [auth('login', api.check)],
      },
    },
  ],
};
