<template lang="pug">
v-app
  TheNavbar(v-if="!isGuest")
  v-main
    keep-alive
      router-view

  Goto

  TheAuthModal(v-if="softAuth")

  TheFooter(v-if="!isGuest")

  TheModalContainer

  notifications.notifications--theme-admin(
    group="error"
    position="top right"
    classes="b-notification b-notification--error b-notification--theme-admin"
  )
  notifications.notifications--theme-admin(
    group="note"
    position="top right"
    classes="b-notification b-notification--info b-notification--theme-admin"
  )


</template>

<script>
import TheModalContainer from '@/components/TheModalContainer';
import TheNavbar from '@/components/TheNavbar';
import TheFooter from '@/components/TheFooter';
import TheAuthModal from '@/components/TheAuthModal';
import eventBus from '@/plugins/eventBus';
import Goto from '@/components/Goto.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    TheModalContainer,
    TheNavbar,
    TheFooter,
    TheAuthModal,
    Goto,
  },

  computed: {
    ...mapGetters('AUTH', ['softAuth', 'isAuthorized']),
    isGuest() {
      return this.softAuth === this.isAuthorized;
    },
  },

  mounted() {
    eventBus.$on('notFound', () => {
      this.$router.push({ name: '404' });
    });
  },
};
</script>
