import { helpers } from 'vuelidate/lib/validators';
import { diffYears } from './helpers';
const { regex, withParams } = helpers;

// FIXME: get rid of validators isTrue and email (read deprecated sections)

/**
 * Checks, if value equal true
 * @deprecated Use your own validators instead
 *
 * @param {*} value
 * @returns {boolean}
 */
export const isTrue = value => !!value === true;

/**
 * Checks, if value has csv file extension
 *
 * @param {*} value
 * @returns {boolean}
 */
export const fileCsv = regex('fileCsv', /^.*\.csv$/);

/**
 * Checks, if value is cyrillic name, surname or patronymic
 *
 * @param {*} value
 * @returns {boolean}
 */
export const cyrillicName = regex(
  'cyrillicName',
  /^[А-ЯЁё][А-Яа-я-Ёё]{0,28}[а-яё]?$/i,
);

/**
 * Checks, if value is date in format yyyy-mm-dd
 *
 * @param {*} value
 * @returns {boolean}
 */
export const date = regex('date', /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/);

/**
 * Checks, if value is driver license
 *
 * @param {*} value
 * @returns {boolean}
 */
export const driverLicense = regex(
  'driverLicense',
  /^[0-9A-Za-zА-Яа-я]{2,20}$/i,
);

/**
 * Checks, if value is phone in format +7 (000) 000-00-00
 *
 * @param {*} value
 * @returns {boolean}
 */
export const phone = regex(
  'phone',
  /^\+7\s\([0-9]{3}\)\s[0-9]{3}-[0-9]{2}-[0-9]{2}$/,
);

/**
 * Checks, if value is email
 * @deprecated Use bult-in email validator
 *
 * @param {*} value
 * @returns {boolean}
 */
export const email = regex(
  'email',
  /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/,
);

/**
 * Checks, if different between current date and date in value more than min arg
 *
 * @param {number} min
 * @returns {boolean}
 */
export const minAge = min =>
  withParams(
    { type: 'minAge', min },
    value => diffYears(value, new Date()) >= min,
  );

/**
 * Checks, if different between current date and date in value less than max arg
 *
 * @param {number} max
 * @returns {boolean}
 */
export const maxAge = max =>
  withParams(
    { type: 'maxAge', max },
    value => diffYears(value, new Date()) < max,
  );

// FIXME: refactor fileImageOrPath
/**
 * Checks, value is image file
 *
 * @param {number} max
 * @returns {boolean}
 */
export const fileImageOrPath = value => {
  const imageFile = /\.(?:jpg|jpeg|png)$/;

  return value === null
    ? true
    : value instanceof File
    ? imageFile.test(value.name)
    : !!(value && value['name'] && typeof value.name === 'string');
};
