import { signService } from '../config';

const SignService = {
  get(params) {
    return signService.loading(signService.get('/users', { params }));
  },

  getUser(id) {
    return signService.loading(signService.get('/users/' + id));
  },

  updateUser(user) {
    return signService.loading(signService.put('/users/' + user.id, user));
  },

  resetPass(id) {
    return signService.loading(signService.put(`/users/${id}/reset`));
  },
};

export default SignService;
