import api from '@/api';
import { convertWorker, convertMedCertificate } from '@/utils/convert/worker';

const state = {
  item: {
    averages: {},
    gender: 'MALE',
    organization: { id: null, name: '' },
    status: true,
  },
  measurements: {},
  photo: null,
};

const getters = {
  item: ({ item }) => {
    const employee = { ...item };

    // we always have dateOfBirth, but just in case i added this logic
    employee.dateOfBirth &&
      (employee.dateOfBirth = item.dateOfBirth?.slice(0, 10));

    employee.averages = {
      diastolicPressure: item.averages?.diastolicPressure
        ? Math.round(item.averages?.diastolicPressure)
        : '-',
      systolicPressure: item.averages?.systolicPressure
        ? Math.round(item.averages?.systolicPressure)
        : '-',
      pulse: item.averages?.pulse ? Math.round(item.averages?.pulse) : '-',
    };
    return employee;
  },
  data: ({ item }) => convertWorker(item),

  medcertificate: ({ item }) =>
    item.medcertificate?.map(convertMedCertificate) || [],

  measurements: state => state.measurements,

  photo: state => state.photo,
};

const mutations = {
  measurements(state, value) {
    state.measurements = value;
  },
  item(state, value) {
    // Мы проверяем, хранятся ли у нас уже данные о средних показателях
    // И отсутствуют ли они в наших новых данных.
    // И если отсутсвуют, объединяем со старыми данными
    state.item =
      value.id === state.item.id && !value.averages
        ? { ...state.item, ...value }
        : value;
  },

  photo(state, value) {
    state.photo = value;
  },
};

const actions = {
  async fetchEmployee({ dispatch, commit }, id) {
    commit('item', {});

    const data = await api('employees').get(id);
    dispatch('fetchMeasurements', id);

    // remove unused fields from data
    commit('photo', data.photo);
    delete data.photo;

    commit('item', data);
    return data;
  },

  async fetchMeasurements({ commit }, id) {
    commit('measurements', {});
    const measurements = await api('employees').getMeasurements(id);
    commit('measurements', measurements);
    return measurements;
  },

  async uploadPhoto({ commit }, { id, photo }) {
    const res = await api('employees').uploadPhoto(id, photo);
    commit('photo', photo);
    return res;
  },

  async create({ dispatch, commit }, { employee, photo }) {
    commit('item', {});
    commit('measurements', {});

    const data = await api('employees').create(
      employee.organization.id,
      sanitizeWorkerData(employee),
    );

    photo && dispatch('uploadPhoto', { id: data.id, photo });

    commit('item', data);
    return data;
  },

  async update({ dispatch, commit }, params) {
    const data = await api('employees').update(
      params.id,
      sanitizeWorkerData(params),
    );
    dispatch('EMPLOYEE_LIST/updateListItemById', data, { root: true });
    commit('item', {});
    commit('item', data);
    return data;
  },

  async fetchValidationRules(_, orgId) {
    let rules = null;
    if (orgId) rules = (await api('organizations').profile(orgId)).rules;

    return (
      rules || {
        personellNumber: {
          pattern: '^[0-9A-Za-z]{4,20}$',
          patternExplanation:
            'Может содержать только латинские буквы и цифры. От 4 до 20 символов.',
        },
      }
    );
  },

  async setEmployee({ dispatch, commit }, id) {
    const employee = await dispatch('EMPLOYEE_LIST/getEmployeeById', id, {
      root: true,
    });
    commit('item', employee);
  },

  async archivePhoto({ commit, getters }) {
    const response = await api('employees').archivePhoto(getters.photo.photoId);
    commit('photo', response);
    return response;
  },

  import(_, { organizationId, file, onlyCreate }) {
    return api('employees').import(organizationId, file, onlyCreate);
  },

  resetPass(_, id) {
    return api('employees').resetPass(id);
  },

  resetItem({ commit }) {
    commit('item', {
      averages: {},
      gender: 'MALE',
      organization: { id: null, name: '' },
      status: true,
    });
    commit('photo', null);
  },
};

// FIXME: idk if this function is be fixed for now with current
// back-to-front format
function sanitizeWorkerData(data) {
  data = { ...data };
  data.phone = data.phone ? data.phone.replace(/[()\s-]/g, '') : null;
  // NOTE:backend expects 0 hours 0 minutes and zero timezone in the date of birth, lol
  data.dateOfBirth = data.dateOfBirth + 'T00:00:00.000+00:00';
  delete data.averages;
  delete data.age;
  delete data.medcertificate;

  data.patronymic = data.patronymic ? data.patronymic : null;

  // FIXME: decide about data format on create
  // and update with backend
  data.organizationId = data.organization?.id;

  // NOTE: artefact of your 'perfect' backend
  // u can't change it, u never edit it, but backend needs it
  data.profession = 'driver';

  return data;
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
