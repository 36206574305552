export default function ({ $store, $notify, next }) {
  $store.dispatch('EMPLOYEE_LIST/fetchList', false).catch(() => {
    $notify({
      group: 'error',
      type: 'error',
      title: `Произошла ошибка загрузки работников. Попробуйте позже.`,
      text: '',
    });
  });

  return next();
}
