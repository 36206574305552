import { VSelect } from 'vuetify/lib';
import { wrapper, isDisabled } from './utils';

export default [
  {
    name: 'StringList',
    type: 'control',
    pattern: [
      x => x.type === 'string',
      x => typeof x.title === 'string',
      x => typeof x.default === 'string',
      x => Array.isArray(x.enum),
      x =>
        Array.isArray(x.enum) &&
        x.enum.filter(item => typeof item === 'string').length ===
          x.enum.length,
    ],
    valuePath: 'default',
    component({ $h, $schema, $value }) {
      const content = $h(VSelect, {
        props: {
          value: $value(),
          label: $schema.title,
          items: $schema.enum,
          outlined: true,
          disabled: isDisabled($schema),
        },
        on: {
          change(value) {
            $value(value);
          },
        },
      });

      return wrapper($h, content);
    },
  },

  {
    name: 'MultiArrayContainStringList',
    type: 'control',
    pattern: [
      x => x.type === 'array',
      x => typeof x.title === 'string',
      x => Array.isArray(x.default),
      x => typeof x.additionalItems === 'boolean' && x.additionalItems,
      x => typeof x.items === 'object',
      x => typeof x.items === 'object' && x.items.type === 'string',
      x => typeof x.items === 'object' && Array.isArray(x.items.enum),
    ],
    valuePath: 'default',
    component({ $h, $schema, $value }) {
      const content = $h(VSelect, {
        props: {
          value: $value(),
          label: $schema.title,
          items: $schema.items.enum,
          outlined: true,
          disabled: isDisabled($schema),
          multiple: true,
        },
        on: {
          change(value) {
            $value(value);
          },
        },
      });

      return wrapper($h, content);
    },
  },

  {
    name: 'ArrayContainArrayList',
    type: 'control',
    pattern: [
      x => x.type === 'array',
      x => typeof x.title === 'string',
      x => typeof x.additionalItems === 'boolean' && x.additionalItems,
      x => Array.isArray(x.default),
      x => Array.isArray(x.enum),
      x =>
        Array.isArray(x.enum) &&
        x.enum.filter(item => Array.isArray(item)).length === x.enum.length,
    ],
    valuePath: 'default',
    component({ $h, $schema, $value }) {
      const defaultValue = ($value() || $schema.default).join(' ');

      const content = $h(VSelect, {
        props: {
          value: defaultValue,
          label: $schema.title,
          items: $schema.enum.map(item => item.join(' ')),
          outlined: true,
          disabled: isDisabled($schema),
        },
        on: {
          change(value) {
            $value(value.split(' '));
          },
        },
      });

      return wrapper($h, content);
    },
  },

  {
    name: 'ArrayContainObjectsList',
    type: 'control',
    pattern: [
      x => x.type === 'array',
      x => typeof x.title === 'string',
      x => Array.isArray(x.default),
      x => typeof x.additionalItems === 'boolean' && x.additionalItems,
      x => typeof x.items === 'object',
      x => typeof x.items === 'object' && x.items.type === 'object',
      x =>
        typeof x.items === 'object' &&
        !!x.items.enum &&
        Array.isArray(x.items.enum),
      x => x.items.enum.length !== 0 && x.items.enum[0].name,
      x => x.items.enum.length !== 0 && x.items.enum[0].type,
    ],
    valuePath: 'default',
    component({ $h, $schema, $value }) {
      const value = $value();
      let defaultItems = null;

      if (Array.isArray(value)) {
        defaultItems = value.map(item => {
          return {
            name: `${item.type} - ${item.name}`,
            value: JSON.stringify(item),
          };
        });
      }

      const items = $schema.items.enum.map(item => {
        return {
          name: `${item.type} - ${item.name}`,
          value: JSON.stringify(item),
        };
      });

      const content = $h(VSelect, {
        props: {
          value: defaultItems,
          label: $schema.title,
          items,
          itemText: 'name',
          itemValue: 'value',
          outlined: true,
          disabled: isDisabled($schema),
          multiple: true,
        },
        on: {
          change(value) {
            $value(value.map(val => JSON.parse(val)));
          },
        },
      });

      return wrapper($h, content);
    },
  },
];
