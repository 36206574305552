import Vue from 'vue';
import store from '@/store';
import { RIGHTS_MAP } from '@/utils/constants';

/**
 * Check if user has specific right
 * @param {*} right Right name, usually in uppercase
 * @param {*} depricated Depricated arg, not use it
 */
export function can(right, depricated) {
  right = RIGHTS_MAP[right] || right;

  if (depricated) {
    console.warn(`Can: ${right} ${depricated} is depricated`);
    return false;
  }

  process.env.VUE_APP_CAN_DEBUG &&
    // eslint-disable-next-line no-console
    console.log(`Can: ${right}, ${store.getters['AUTH/can'](right)}`);

  // we use store, not this.$store, because context in function can be lost
  return store.getters['AUTH/can'](right);
}

const canPlugin = {
  install(_Vue, dictionary) {
    Vue.prototype.$can = can;
    store.$can = can;
  },
};

Vue.use(canPlugin);

export default canPlugin;
