import api from '@/api';

const state = {
  items: [],
};

const getters = {
  tags: state => state.items,
};

const mutations = {
  setTags(state, tags) {
    state.items = tags;
  },
};

const actions = {
  async fetch({ commit, getters }, orgId) {
    try {
      const {
        data: { data },
      } = await api('terminals/dictionary').tags(
        orgId.orgId === 'openpoints' ? null : orgId,
      );
      commit('setTags', data);

      return data;
    } catch (e) {
      return e;
    }
  },

  reset({ commit }) {
    commit('setTags', []);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
