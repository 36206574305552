import { name, date, phone } from './common';
import { GENDER } from '@/utils/constants.js';

export function convertWorker(worker) {
  return {
    id: worker.id,
    name: name(worker),
    dateOfBirth: date(worker.dateOfBirth),
    age: worker.age,
    gender: GENDER[worker.gender],
    personellNumber: worker.personellNumber,
    phone: phone(worker.phone),
    license: worker.license,
    organization: worker.organization.name,
    organizationId: worker.organization.id,
    status: status(worker.status),
    hash: worker?.hash || '–',
  };
}

export function convertMedCertificate(item) {
  return {
    reason: item.reason,
    period: period(item.created, item.expired),
    url: item.url,
  };
}

export function status(value) {
  return value ? 'Активный' : 'Неактивный';
}

export function period(created, expired) {
  return `От ${date(created)} до ${date(expired)}`;
}
