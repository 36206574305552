import { VSelect } from 'vuetify/lib';

export default {
  name: 'Logging',

  props: {
    value: String,
    items: Object,
    label: String,
    errorMessages: {
      type: Array,
      default: () => [],
    },
    error: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    list() {
      return this.items.appenders.items.properties.thresholdLevel.enum;
    },
  },

  methods: {
    result(data) {
      const loggerProps = this.items.loggers.properties;
      const result = {
        appenders: [{ thresholdLevel: data }],
        loggers: {},
      };

      for (const item of Object.entries(loggerProps)) {
        result.loggers[item[0]] = data;
      }

      this.$emit('change', result);
    },
  },

  render(h) {
    return h(VSelect, {
      props: {
        label: this.label,
        value: this.value,
        items: this.list,
        'error-messages': this.errorMessages,
        error: this.error,
        outlined: true,
      },
      on: {
        change: value => this.result(value),
      },
    });
  },
};
