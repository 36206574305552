import { InspectionTypes } from '@/utils/constants.js';
import { name, dateTime } from './common';

export function convertInspection(inspeciton) {
  return {
    id: inspeciton.id,
    type: type(inspeciton.type),
    medworker: name(inspeciton?.medworker),
    hash: inspeciton?.medworker?.hash ?? '–',
    eds: inspeciton?.medworker?.eds ?? '-',
    dateTime: dateTime(inspeciton?.dateTime),
    duration: duration(inspeciton?.duration),
    organization: inspeciton?.organization?.name ?? '-',
    hostname: inspeciton?.hostname,
    comment: inspeciton?.comment,
    failConclusion: inspeciton?.failConclusion,
    pdfName: inspeciton?.id + '.pdf',
    pdfUrl: inspeciton?.pdf,
  };
}

export function type(value) {
  return InspectionTypes[value];
}

export function duration(value) {
  if (!value) return '';

  const minutes = String(Math.floor(value / 60)).padStart(2, '0');
  const seconds = String(value % 60).padStart(2, '0');
  return minutes + ':' + seconds;
}
