import api from '../../../api';

const state = {
  recipient: {
    firstname: 'Тест',
    surname: 'Тест',
    patronymic: 'Тест',
    contact: '',
    typeIds: [],
  },
  email: '',
  phone: '',
};

const getters = {
  getRecipient: state => state.recipient,
  firstname: state => state.recipient.firstname,
  surname: state => state.recipient.surname,
  patronymic: state => state.recipient.patronymic,
  email: state => state.email,
  phone: state => state.phone,
  typeIds: state => state.recipient.typeIds,
};

const mutations = {
  name(state, firstname) {
    state.recipient.firstname = firstname;
  },
  surname(state, surname) {
    state.recipient.surname = surname;
  },
  patronymic(state, patronymic) {
    state.recipient.patronymic = patronymic;
  },
  phone(state, phone) {
    state.phone = phone;
  },
  email(state, email) {
    state.email = email;
  },
  contact(state, data) {
    state.recipient.contact = data;
  },
  types(state, typeIds) {
    state.recipient.typeIds = typeIds;
  },
  orgid(state, orgId) {
    state.recipient.orgId = orgId;
  },
  orgname(state, orgName) {
    state.recipient.orgName = orgName;
  },
  hostname(state, hostName) {
    state.recipient.hostName = hostName;
  },
  hostid(state, hostId) {
    state.recipient.hostId = hostId;
  },
  reset(state) {
    state.recipient = {
      firstname: 'Тест',
      surname: 'Тест',
      patronymic: 'Тест',
      contact: '',
      typeIds: [],
    };
    state.email = null;
    state.phone = null;
  },
};

const actions = {
  async add({ commit, getters }, value) {
    if (value === 'phone') {
      commit('contact', getters.phone.replace(/[+()\s-]/g, ''));
    } else {
      commit('contact', getters.email);
    }

    return api('notifications').edit([getters.getRecipient]);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
