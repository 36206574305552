import { InspectionReactionType } from '@/utils/constants.js';

export function convertMedical(meddata) {
  return {
    pressure: pressure(meddata),
    pulse: meddata.pulse || '–',
    alcohol: alcohol(meddata.alcohol),
    complaints: complaints(meddata.complaints),
    sleep: sleep(meddata.sleep),
    temperature: temperature(meddata.temperature),
    reactionType: reactionType(meddata.reactionType),
    reactionResult: reactionResult(meddata),
  };
}

export function pressure(value) {
  return !value.systolicPressure
    ? '–'
    : `${value.systolicPressure} / ${value.diastolicPressure}`;
}

export function alcohol(value) {
  return !value ? '–' : value;
}

export function complaints(value) {
  if (value === null) return '–';
  return value ? 'присутствуют' : 'отсутствуют';
}

export function sleep(value) {
  if (value === null) return '–';
  return value ? 'более 8 часов' : 'менее 8 часов';
}

export function temperature(value) {
  return value === null || value === '0' || value === '0.0' ? '–' : value;
}

export function reactionType(value) {
  if (value === null) return null;

  const obj = InspectionReactionType.find(item => item.type === value);

  return obj?.name || 'Неизвестное тестирование';
}

export function reactionResult({ reactionType, reactionScore }) {
  if (reactionType === null) return null;

  if (reactionType === 'NUMBERS') {
    return reactionScore > 50 ? 'Отклонение' : 'Норма';
  } else if (reactionType === 'DOTS') {
    return reactionScore < 20 ? 'Отклонение' : 'Норма';
  } else return 'Неизвестный результат';
}
