import { reports } from '../config';

const ReportService = {
  list() {
    return reports
      .loading(reports.get('/reports'), 'reports_list')
      .then(res => res.data.data);
  },

  execute(reportId, filters, format = 'XLSX') {
    if (format === 'JSON') {
      return this.executorJSON(reportId, filters);
    }

    const multiple = Array.isArray(filters);

    if (multiple) {
      return this.executorZip(reportId, filters, format);
    } else {
      return this.executorPlain(reportId, filters, format);
    }
  },

  async executorJSON(reportId, filters) {
    const url =
      '/reports/' +
      reportId +
      '?filters=' +
      encodeURIComponent(JSON.stringify(filters));
    return reports.get(url).then(res => res.data.data);
  },

  async executorPlain(reportId, filters, format) {
    const query = {
      reportId,
      format,
      filters,
    };

    const id = await reports
      .post('/reports/tasks', query)
      .then(res => res.data.data);

    return this.poller(id);
  },

  async executorZip(reportId, filters, format) {
    const query = filters.map(item => ({
      reportId,
      format,
      filters: item,
    }));

    const {
      data: { data },
    } = await reports.post('/reports/zip/tasks', {
      reports: query,
    });
    return this.poller(data);
  },

  async poller(id) {
    const data = await reports
      .get('/reports/tasks/' + id)
      .then(res => res.data.data);

    if (data.status === 'done') {
      return data.output.data;
    } else if (data.status === 'waiting' || data.status === 'inProgress') {
      await new Promise(resolve => setTimeout(resolve, 3000));
      return this.poller(id);
    } else {
      throw new Error(data);
    }
  },
};

export default ReportService;
