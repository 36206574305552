import api from '@/api';

const state = {
  configs: [],
};

const getters = {
  schemas: state => state.configs,
};

const mutations = {
  setConfigs: (state, configs) => {
    state.configs = configs;
  },
};

const actions = {
  async getSchemas({ commit, getters }, id) {
    try {
      const schema = await api('terminals/schemas').schema(id);
      // const scaffold = await api.get('schemas').scaffold(id);

      commit('setConfigs', schema);

      return Promise.resolve(true);
    } catch (e) {
      return Promise.reject(e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
