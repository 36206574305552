import { notifications } from '../config';

const NotificationsService = {
  getRecipients(params) {
    return notifications
      .loading(notifications.get('/recipient', { params }), 'recipients')
      .then(res => res.data);
  },

  add(data) {
    return notifications.loading(notifications.post('/notifications/', data));
  },

  edit(data) {
    return notifications
      .loading(notifications.put('/recipient', data), 'edit')
      .then(data => data.data);
  },

  delete(item) {
    return notifications
      .loading(notifications.delete('/recipient', { data: item }))
      .then(res => res.data);
  },

  getById(id) {
    return notifications.loading(notifications.get(`/notifications/${id}`));
  },

  // for notifications-dictionary
  types() {
    return notifications
      .loading(notifications.get('/type'))
      .then(res => res.data);
  },

  groups() {
    return notifications
      .loading(notifications.get('/group'))
      .then(res => res.data);
  },

  searchContact(searchString) {
    return notifications
      .loading(
        notifications.get(`/contacts?contactSubStr=${searchString}`),
        'searchContact',
      )
      .then(res => res.data);
  },

  searchHost(searchString) {
    return notifications.loading(
      notifications.get(`/host/search?searchString=${searchString}`),
      'searchHost',
    );
  },

  searchOrg(searchString) {
    return notifications
      .loading(
        notifications.get(`/organization/search?searchString=${searchString}`),
        'searchOrg',
      )
      .then(res => res.data);
  },

  getOrgs() {
    return notifications
      .loading(notifications.get('/organization'))
      .then(res => res.data);
  },

  getHosts() {
    return notifications
      .loading(notifications.get('/host'))
      .then(res => res.data);
  },

  getBindings(params) {
    return notifications
      .loading(notifications.get('/hostorg', { params }), 'bindings')
      .then(data => data.data);
  },

  editBinding(data) {
    return notifications.loading(
      notifications.put(`/hostorg/${data.id}`, data),
      'editBinding',
    );
  },

  addBinding(data) {
    return notifications.loading(notifications.post('/hostorg', data));
  },
};

export default NotificationsService;
