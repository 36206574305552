import { wrapper, errorMessage, isDisabled } from './utils';
import { VTextField } from 'vuetify/lib';

export default [
  {
    name: 'StringTextField',
    type: 'control',
    pattern: [
      x => x.type === 'string',
      x => typeof x.title === 'string',
      x => !x.pattern,
      x => !x.enum,
    ],
    valuePath: 'default',
    component({ $h, $schema, $value, $errors }) {
      const description = $schema.description
        ? { messages: $schema.description }
        : {};

      const content = $h(VTextField, {
        props: {
          value: $value(),
          label: $schema.title,
          outlined: true,
          disabled: isDisabled($schema),
          'error-messages': $errors(),
          error: $errors().length !== 0,
          ...description,
        },
        on: {
          input: value => $value(value),
        },
      });

      return wrapper($h, content);
    },
    validate({ $schema, $parentSchema, $name }) {
      const rules = [];

      if (
        $parentSchema &&
        $parentSchema.required &&
        $parentSchema.required.includes($name)
      ) {
        rules.push({
          properties: {
            value: { type: 'string', minLength: 1 },
          },
          required: ['value'],
          ...errorMessage('required', $schema, $parentSchema),
        });
      }

      return rules;
    },
  },

  {
    name: 'StringWithPatternTextField',
    type: 'control',
    pattern: [
      x => x.type === 'string',
      x => typeof x.title === 'string',
      x => typeof x.pattern === 'string',
      x => !x.enum,
    ],
    valuePath: 'default',
    component({ $h, $schema, $value, $valid, $errors }) {
      const description = $schema.description
        ? { messages: $schema.description }
        : {};

      const content = $h(VTextField, {
        props: {
          value: $value(),
          label: $schema.title,
          outlined: true,
          disabled: isDisabled($schema),
          'error-messages': $errors(),
          error: $errors().length !== 0,
          ...description,
        },
        on: {
          input: value => {
            $valid(value);
            $value(value);
          },
        },
      });

      return wrapper($h, content);
    },
    validate({ $schema, $parentSchema, $name }) {
      const rules = [];

      if (
        $parentSchema &&
        $parentSchema.required &&
        $parentSchema.required.includes($name)
      ) {
        rules.push({
          properties: {
            value: { type: 'string', minLength: 1 },
          },
          required: ['value'],
          ...errorMessage('required', $schema, $parentSchema),
        });
      }

      if ($schema.pattern) {
        rules.push({
          properties: {
            value: { type: 'string', pattern: $schema.pattern },
          },
          ...errorMessage('pattern', $schema, $parentSchema),
        });
      }

      return rules;
    },
  },
];
