import api from '@/api';
import { REPORTS } from '@/utils/constants';

const state = {
  availableReports: [],
  filters: {
    orgId: null,
    orgIds: null,
    inspectionTypes: null,
    inspectionResults: null,
    dateStart: null,
    dateEnd: null,
    medicGroupIds: [1],
    timeStart: '00:00',
    timeFinish: '23:59',
    includeTest: false,
    includeTechErrors: false,
  },
  results: [],
  expandedResults: [],
};

const getters = {
  availableReports: state => state.availableReports,
  filters: state => state.filters,
  results: state => state.results,
  isExpandedResult: state => id => state.expandedResults.includes(id),
};

const mutations = {
  availableReports: (state, value) => {
    state.availableReports = value;
  },
  filters: (state, value) => {
    state.filters = {
      ...state.filters,
      ...value,
    };
  },

  addResult: (state, data) => {
    state.results = [data, ...state.results];
  },
  clearResults: state => {
    state.results = [];
    state.expandedResults = [];
  },
  changeResult: (state, data) => {
    const index = state.results.findIndex(item => item.id === data.id);
    state.results = [
      ...state.results.slice(0, index),
      { ...state.results[index], ...data },
      ...state.results.slice(index + 1),
    ];
  },
  expandedResults: (state, value) => {
    state.expandedResults = value;
  },
};

const actions = {
  async fetchReports({ commit }) {
    let data = await api('reports').list();
    data = data
      .filter(item => REPORTS[item.id])
      .map(item => ({ ...item, ...REPORTS[item.id] }))
      .sort((a, b) => (a.priority || 99) - (b.priority || 99));

    commit('availableReports', data);
  },
  async export({ commit }, { id, filters, format }) {
    const itemId = new Date().valueOf();
    commit('addResult', {
      id: itemId,
      reportId: id,
      isLoading: true,
      error: null,
      timestamp: new Date().valueOf(),
      format,
      data: null,
    });

    try {
      const data = await api('reports').execute(
        id,
        filters,
        format.toUpperCase(),
      );
      commit('changeResult', {
        id: itemId,
        isLoading: false,
        data,
      });
      data.stats && commit('expandedResults', [itemId]);

      return data;
    } catch (error) {
      commit('changeResult', { id: itemId, isLoading: false, error });
      throw error;
    }
  },
  expandResult({ commit, state }, id) {
    commit('expandedResults', [...state.expandedResults, id]);
  },
  unexpandResult({ commit, state }, id) {
    const index = state.expandedResults.findIndex(item => item === id);
    commit('expandedResults', [
      ...state.expandedResults.slice(0, index),
      ...state.expandedResults.slice(index + 1),
    ]);
  },
  clearResults({ commit }) {
    commit('clearResults');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
