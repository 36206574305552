export default [
  {
    name: 'EmptyObject',
    type: 'empty',
    pattern: [
      x => x.type === 'object',
      x => typeof x.const === 'object',
      x => !x.properties,
    ],
    valuePath: 'const',
  },

  {
    name: 'EmptyString',
    type: 'empty',
    pattern: [x => x.type === 'string', x => typeof x.const === 'string'],
    valuePath: 'const',
  },

  {
    name: 'EmptyInteger',
    type: 'empty',
    pattern: [x => x.type === 'integer', x => typeof x.const === 'number'],
    valuePath: 'const',
  },

  {
    name: 'EmptyArray',
    type: 'empty',
    pattern: [x => x.type === 'array', x => Array.isArray(x.const)],
    valuePath: 'const',
  },
];
