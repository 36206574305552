import { phone } from './common';

export function convertOrganization(organization) {
  return {
    id: organization.id,
    name: organization.name,
    phone: phone(organization.phone),
    address: organization.address,
    inn: organization.inn,
  };
}
