<template lang="pug">
v-footer( padless color="transparent")
  TheSupportForm(
    v-if="showSupportForm"
    @close="showSupportForm = false"
  )
  v-col.grey--text.d-flex.justify-space-between.align-center
    v-col(cols="3")
      div MEDPOINT24 − ДИСПЕТЧЕР
      div 
        a(
          target="_blank"
          :href="userGuideURL.terminal"
        ) Руководство пользователя ПО Терминал
      div
        a(
          target="_blank"
          :href="userGuideURL.dispatcher"
        ) Руководство пользователя ПО Диспетчер
    v-col.text-center(cols="6")
      div
        div Позвонить бесплатно с мобильного телефона
          a(href="tel:*1324")  *1324
        div Бесплатный номер для звонков с любых телефонов
          a(href="tel:88005507611")  8-800-550-76-11
        div.text-decoration-underline(
              style="cursor: pointer;"
              color="primary"
              @click="showSupportForm = true"
            ) Написать в поддержку
    v-col.text-right(cols="3")
      div.mr-15
        div Medpoint24
        div {{copyright}}
</template>

<script>
import TheSupportForm from '@/components/TheSupportForm';
import { USER_GUIDE_URL } from '@/utils/constants.js';

export default {
  components: {
    TheSupportForm,
  },
  data() {
    return {
      showSupportForm: false,
      copyright: '2017 – ' + new Date().getFullYear() + ' г. © DistMed.com',
      userGuideURL: USER_GUIDE_URL,
    };
  },
};
</script>
