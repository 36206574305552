import { dispatcher, photoArchive } from '../config';

const EmployeesService = {
  search(searchString) {
    return dispatcher
      .get('/employees/search/' + searchString)
      .then(res => res.data.data);
  },

  get(id) {
    return dispatcher
      .loading(dispatcher.get(`/employees/${id}`), 'worker')
      .then(res => res.data.data);
  },

  uploadPhoto(id, photo) {
    const formData = new FormData();
    formData.append('file', photo);

    return dispatcher
      .post(`/employees/${id}/photo`, formData)
      .then(res => res.data.data.photo);
  },

  archivePhoto(photoId) {
    return photoArchive
      .patch(`/new-auth/photo/${photoId}/archive`)
      .then(res => res.data);
  },

  list(params) {
    return dispatcher
      .loading(dispatcher.get('/employees', { params }), 'workers')
      .then(res => res.data);
  },

  create(orgId, params) {
    return dispatcher
      .loading(
        dispatcher.post(`/organizations/${orgId}/employees`, params),
        'worker',
      )
      .then(res => res.data.data);
  },

  update(id, params) {
    return dispatcher
      .loading(dispatcher.patch('/employees/' + id, params), 'employee_update')
      .then(res => res.data.data);
  },

  import(orgId, file, onlyCreate = true) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('onlyCreate', onlyCreate);

    return dispatcher
      .loading(
        dispatcher.post(`organizations/${orgId}/employees/import`, formData),
        'import_workers',
      )
      .then(res => res.data.data);
  },

  export(params) {
    return dispatcher
      .get('/employees/export', { params })
      .then(res => res.data.data);
  },

  resetPass(id) {
    return dispatcher.post(`/employees/${id}/reset`).then(res => res.data);
  },

  getMeasurements(id) {
    return dispatcher
      .loading(
        dispatcher.get(`/employees/${id}/measurements`),
        'employee_measurements',
      )
      .then(res => res.data.data);
  },

  getLinkToImportTemplate() {
    return process.env.VUE_APP_API + '/employees/import/template';
  },
};

export default EmployeesService;
