export default async function ({ $store, $notify, next }) {
  $store.dispatch('NOTIFICATIONS_LIST/fetchList', false).catch(() => {
    $notify({
      group: 'error',
      type: 'error',
      title: 'Произошла ошибка загрузки получателей. Попробуйте позже.',
      text: '',
    });
  });
  return next();
}
