import { dispatcher } from '../config';

const OrganizationService = {
  list(params) {
    return dispatcher.get('/organizations', { params }).then(res => res.data);
  },

  get() {
    return dispatcher.loading(
      dispatcher.get('/organizations/names').then(res => res.data.data),
      'organizations:names',
    );
  },

  export(params) {
    return dispatcher
      .get('/organizations/export', { params })
      .then(res => res.data.data);
  },

  profile(orgId) {
    return dispatcher
      .loading(
        dispatcher.get(`organizations/${orgId}/profile`),
        'profile_organization',
      )
      .then(res => res.data.data);
  },
};

export default OrganizationService;
