import Vue from 'vue';
import middleware from '@frontend/middleware';
import router from '@/router';

const middlewarePlugin = middleware;

Vue.use(middlewarePlugin, {
  router,
  dependencies: ['$store', '$notify', '$can'],
});

export { middlewarePlugin as middleware };
export default middlewarePlugin;
