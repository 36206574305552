import api from '@/api';

const state = {
  query: {
    order: 'id',
    orderType: false,
    page: 1,
    limit: 50,
    // filters
    orgId: null,
    hostId: null,
    groupId: null,
  },
  total: 0,
  items: [],

  orgType: 'org',
  editItem: {},
};

const getters = {
  total: state => state.total,
  query: ({ query }, getters, rootState, rootGetters) => ({
    ...query,
    orgId:
      getters.orgType === 'org' &&
      query.orgId &&
      query.orgId.length !== 0 &&
      rootGetters['NOTIFICATIONS/orgList'].length !== query.orgId.length
        ? query.orgId.join()
        : null,
    hostId:
      getters.orgType === 'host' &&
      query.hostId &&
      query.hostId.length !== 0 &&
      rootGetters['NOTIFICATIONS/hostList'].length !== query.hostId.length
        ? query.hostId.join()
        : null,
    groupId:
      query.groupId && query.groupId.length !== 0 ? query.groupId.join() : null,
    orderType: query.orderType ? 'DESC' : 'ASC',
  }),
  limit: state => state.query.limit,
  order: state => state.query.order,
  orderType: state => state.query.orderType,
  currentPage: state => state.query.page,
  totalPages: state =>
    !state.total ? 0 : Math.ceil(state.total / state.query.limit),
  isShowPagination: state => state.total / state.query.limit > 1,
  items: state => state.items,

  // filter logic
  selectedOrgs: state => state.query.orgId,
  selectedHosts: state => state.query.hostId,
  selectedGroups: state => state.query.groupId,
  orgType: state => state.orgType,
  editItem: state => state.editItem,
};

const mutations = {
  orderBy: (state, value) => (state.query.order = value),
  orderType: (state, value) => (state.query.orderType = value),
  items: (state, value) => (state.items = value),
  total: (state, value) => (state.total = value),
  limit: (state, value) => (state.query.limit = value),
  currentPage: (state, value) => (state.query.page = value),
  resetCurrentPage: state => (state.query.page = 1),

  orgId(state, value) {
    state.query.orgId = value;
  },
  selectedOrgs(state, value) {
    state.query.orgId = value;
  },
  selectedHosts(state, value) {
    state.query.hostId = value;
  },
  selectedGroups(state, value) {
    state.query.groupId = value;
  },
  initGroups(state, value) {
    state.query.groupId = value;
  },
  initOrgs(state, value) {
    state.query.orgId = value;
  },
  initHosts(state, value) {
    state.query.hostId = value;
  },
  orgType(state, value) {
    state.orgType = value;
  },
  editItem(state, value) {
    state.editItem = value;
  },
};

const actions = {
  init({ commit, dispatch }) {
    ['selectedOrgs', 'selectedHosts', 'selectedGroups', 'limit'].forEach(
      mutation => {
        this.$subscribeOn(`BINDINGS/${mutation}`, () =>
          commit('resetCurrentPage'),
        );
      },
    );

    // load new bindings when any of these r triggered
    [
      'selectedOrgs',
      'selectedHosts',
      'selectedGroups',
      'orderBy',
      'orderType',
      'limit',
      'currentPage',
    ].forEach(mutation => {
      this.$subscribeOn(`BINDINGS/${mutation}`, () => dispatch('fetchList'));
    });
  },

  async fetchList({ getters, state, commit }, refresh = true) {
    if (!refresh && getters.items.length)
      return { data: getters.items, total: state.total };

    commit('total', 0);
    commit('items', []);

    const { data, total } = await api('notifications').getBindings(
      getters.query,
    );

    commit('items', data);
    commit('total', total);
    return { data, total };
  },

  resetFilters({ commit, getters, rootGetters }) {
    commit('initOrgs', rootGetters['NOTIFICATIONS/orgIds']);
    commit('initHosts', rootGetters['NOTIFICATIONS/hostIds']);
    commit('initGroups', rootGetters['NOTIFICATIONS/groupIds']);
    commit('currentPage', 1);
  },

  async editBinding({ getters, state, dispatch }, selectedGroup) {
    const query = { id: state.editItem.id };
    query.groupId = selectedGroup;
    if (state.editItem.host) {
      query.hostId = state.editItem.host.id;
    } else if (state.editItem.orgId) {
      query.orgId = state.editItem.orgId;
    }

    try {
      const data = await api('notifications').editBinding(query);
      return data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  },

  async addBinding({ getters, dispatch }, query) {
    try {
      const data = await api('notifications').addBinding(query);
      return data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
