export default async function ({ $store, next, $notify }) {
  await $store.dispatch('TERMINALS_LIST/queryListPage').catch(() => {
    $notify({
      group: 'error',
      type: 'error',
      title: `Произошла ошибка загрузки терминалов. Попробуйте позже.`,
      text: '',
    });
  });
  next();
}
