import { terminals } from '../../config';

const DictionaryRepository = {
  organizations() {
    return terminals
      .loading(terminals.get('/terminals/tmp/organizations'), 'organizations')
      .then(res => res.data);
  },

  versions() {
    return terminals
      .loading(terminals.get('/versions'), 'versions')
      .then(res => res.data);
  },

  tags(params) {
    return terminals
      .loading(terminals.get('/tags', { params }), 'tags')
      .then(res => res.data);
  },
};

export default DictionaryRepository;
