<template lang="pug">
v-dialog(
  :value="true"
  max-width="450"
  persistent
)
  v-card
    v-card-title Ваша сессия истекла.
    v-card-text
      p Необходимо выполнить авторизацию.
      v-row
        v-col.py-0(cols="12")
          v-text-field(
            label="Логин"
            :value="username"
            :disabled="true"
            outlined
            depressed
            dense
            @input="authError = null"
            :error-messages="getValidationErrors('username')"
            :error="!!authError"
          )
        v-col.py-0(cols="12")
          v-text-field(
            label="Пароль"
            v-model="password"
            type="password"
            outlined
            depressed
            dense
            @input="authError = null"
            v-on:keyup.enter="onSubmit"
            :error-messages="authError || getValidationErrors('password')"
          )
      v-row.py-0
        v-col.py-0.mt-0(cols="6")
          v-btn.mt-0(
            @click="signOut"
            outlined
            height="40"
            color="primary"
            :disabled="$root.isDisabled('recovery_session')"
            block
          ) Выйти
        v-col.py-0.mt-0(cols="6")
          v-btn.mt-0(
            @click="onSubmit"
            depressed
            height="40"
            color="primary"
            :loading="$root.isLoading('recovery_session')"
            block
          ) Продолжить
</template>

<script>
import mixins from '@/utils/mixins';
import Validate from '@/validations/signin';
import { mapActions, mapGetters } from 'vuex';

export default mixins(Validate).extend({
  data() {
    return {
      password: '',
      authError: null,
    };
  },
  computed: {
    ...mapGetters('AUTH', ['testCredentials', 'currentUser']),
    username() {
      return this.currentUser.email;
    },
  },
  watch: {
    // For comfortable development
    testCredentials: {
      immediate: true,
      handler(value) {
        const credentials = value.find(([key]) => key === this.username) || [];
        this.password = credentials[1];
      },
    },
  },
  methods: {
    ...mapActions('AUTH', ['signIn', 'signOut']),
    async onSubmit() {
      this.authError = null;
      this.$v.$touch();
      this.setServerValidationErrors([]);

      if (this.$v.$invalid) {
        return null;
      }

      try {
        await this.signIn({
          password: this.password,
          recovery: true,
        });
        this.password = '';
        this.$v.$reset();
      } catch (error) {
        if (error.response?.status === 400) {
          this.setServerValidationErrors(error.response.data?.inner);
        } else {
          this.authError = error.message;
        }
      }
    },
  },
});
</script>

<style></style>
