import api from '@/api';
import { convertWorker } from '@/utils/convert/worker';
import { convertInspection } from '@/utils/convert/inspection';
import { convertMedical } from '@/utils/convert/medical';

const state = {
  item: {},
  documents: {},
};

const getters = {
  item: state => state.item,
  id: state => state.item?.id,
  workerId: state => state.item?.employee?.id,
  workerPhoto: state => state.item?.employee?.photo,
  worker: ({ item }) => (item.employee ? convertWorker(item.employee) : null),
  videoUrl: state => state.item?.meddata?.videoUrl,
  medical: ({ item }) => (item.meddata ? convertMedical(item.meddata) : null),
  data: ({ item }) => convertInspection(item),

  /**
   * Документы к медосмотру
   * @type {Object}
   */
  currentDocuments: state => state.documents,
};

const mutations = {
  /**
   * Установить медосмотр
   * @param {*} state
   * @param {*} value Медосмотр
   */
  item(state, value) {
    state.item = value;
  },
  /**
   * Установить меддокументы
   * @param {*} state
   * @param {*} value Медосмотр
   */
  currentDocument(state, value) {
    state.documents = value;
  },

  workerPhoto: (state, value) => (state.item.employee.photo = value),
};

const actions = {
  /**
   * Получить медосмотр, если он текущий, или запросить с сервера другого
   * @param {*} context
   * @param {Number} id ID медосмотра, которого нужно запросить
   */
  async fetch({ getters, commit }, id) {
    // не запрашиваем, если медосмотр с таким id уже в сторе
    if (getters.item && getters.item.id === id) return getters.item;

    commit('item', {});

    try {
      const data = await api('inspections').get(id);

      commit('item', data);
      return data;
    } catch (error) {
      commit('item', {});
      throw error;
    }
  },
  /**
   * Получить документы с сервера нотификаций для медосмотра
   * @param {*} context
   * @param {Number} id ID медосмотра, которого нужно запросить
   */
  async fetchDocuments({ commit }, id) {
    try {
      const data = await api('inspections').getDocuments(id);

      commit('currentDocument', data);
      return data;
    } catch (error) {
      commit('currentDocument', {});
      throw error;
    }
  },
  /**
   * Запросить печать стикера
   * @param {*} context
   * @param {Number} id ID медосмотра, для которого нужно напечатать стикер
   */
  async printSticker(_, id) {
    await api('inspections').printSticker(id);
  },

  // NOTE: this function is an exact copy of same function in
  // 'employee' module
  async archivePhoto({ commit, getters }) {
    const response = await api('employees').archivePhoto(
      getters.workerPhoto.photoId,
    );
    commit('workerPhoto', response);
    return response;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
