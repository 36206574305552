import { VTextarea } from 'vuetify/lib';
import { wrapper, isDisabled } from './utils';

export default [
  {
    name: 'ArrayTextarea',
    type: 'control',
    pattern: [
      x => x.type === 'array',
      x => typeof x.title === 'string',
      x => Array.isArray(x.default),
      x => typeof x.additionalItems === 'boolean' && x.additionalItems,
      x => typeof x.items === 'object',
      x => typeof x.items === 'object' && x.items.type === 'string',
      x => !x.items.enum,
    ],
    valuePath: 'default',
    component({ $h, $schema, $value }) {
      const value = $value();
      const defaultValue =
        value && Array.isArray(value) ? value.join('\n') : '';

      const content = $h(VTextarea, {
        props: {
          value: defaultValue,
          label: $schema.title,
          outlined: true,
          disabled: isDisabled($schema),
        },
        on: {
          input: value => {
            $value(value.split('\n'));
          },
        },
      });

      return wrapper($h, content);
    },
  },
];
