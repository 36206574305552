import api from '@/api';

const state = {
  items: [],
};

const getters = {
  organizations: state => state.items,
};

const mutations = {
  setOrganizations(state, organizations) {
    state.items = organizations;
  },
};

const actions = {
  async fetch({ getters, commit }) {
    if (getters.organizations.length !== 0) {
      return getters.organizations;
    }

    try {
      const { data } = await api('terminals/dictionary').organizations();
      commit('setOrganizations', Object.freeze(data));
      return data;
    } catch (e) {
      return e;
    }
  },

  reset({ commit }) {
    commit('setOrganizations', []);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
