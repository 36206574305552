/**
 * Factory auth middleware
 * @param {string} routeName, The name route goto if user not authorized
 * @param {function} checkCallback, The callback check user authorized;
 */
export const auth = function (routeName, checkCallback) {
  const goto = routeName;
  const cb = checkCallback;

  return async function ({ next }) {
    const isAuthorized = await cb();

    return isAuthorized
      ? next()
      : next({
          name: goto,
          nextMiddleware: false,
        });
  };
};
