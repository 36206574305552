import api from '@/api';
// import { mergeConfigs } from '@/helpers'

const state = {
  version: null,
  config: null,
  terminals: [],
  schemas: [],
  isReady: false, // Статус готовности данных для формы
};

const getters = {
  config: state => state.config,

  version: state => state.version,

  terminals: state => state.terminals,

  schemas: state => state.schemas,

  isReady: state => state.isReady,
};

const mutations = {
  config(state, config) {
    state.config = config;
  },

  version(state, version) {
    state.version = version;
  },

  schemas(state, schemas) {
    state.schemas = schemas;
  },

  schemaValidateStatus(state, { idx, validStatus }) {
    state.schemas[idx].valid = validStatus;
  },

  isReady(state, isReady) {
    state.isReady = isReady;
  },

  addTerminal(state, options) {
    state.terminals.push({
      id: options.id,
      revision: options.revision,
    });
  },
};

const actions = {
  async getDataForFormEdit({ dispatch, commit }, { ids, versionId }) {
    return Promise.all([
      dispatch('getSchemas', versionId),
      dispatch('getTermianlsById', ids),
    ])
      .then(() => {
        commit('isReady', true);
      })
      .catch(() => {
        dispatch('reset');
      });
  },

  async getSchemas({ commit, dispatch }, versionId) {
    try {
      const schemas = await api('terminals/schemas').schema(versionId);

      commit('schemas', schemas);
    } catch (error) {
      dispatch('reset');
      throw error;
    }
  },

  async getTermianlsById({ commit, dispatch }, ids) {
    const terminalIds = ids;

    try {
      const mergedConfig = await Promise.all(
        terminalIds.map(async id => {
          const { data } = await api('terminals/terminals').getById(id);
          return data;
        }),
      ).then(configs => {
        configs.forEach(config => {
          commit('addTerminal', {
            id: config.id,
            revision: config.revision,
          });
        });

        return configs.reduce((acc, cur) => {
          return { ...acc, ...cur };
        }, {});
      });

      commit('version', mergedConfig.version);
      commit('config', mergedConfig.config);
    } catch (error) {
      dispatch('reset');
      throw error;
    }
  },

  async update({ getters }) {
    const query = {
      version: getters.version,
      terminals: getters.terminals.map(terminal => ({
        ...terminal,
        config: getters.config,
      })),
    };

    try {
      const data = await api('terminals/terminals').update(query);

      return data;
    } catch (error) {
      return error;
    }
  },

  reset({ commit }) {
    commit('version', null);
    commit('config', null);
    commit('schemas', []);
    commit('isReady', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
