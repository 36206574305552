export default async function ({ $store, next, $notify }) {
  const dictionary = [
    $store.dispatch('TERMINALS_ORGANIZATIONS/fetch'),
    $store.dispatch('TERMINALS_VERSIONS/fetch'),
  ];

  await Promise.all(dictionary).catch(() => {
    $notify({
      group: 'error',
      type: 'error',
      title: `Произошла ошибка загрузки справочников. Попробуйте позже.`,
      text: '',
    });
  });

  next();
}
