import { DateTime } from 'luxon';

export function name(value) {
  return `${value?.surname ?? ''} ${value?.name ?? ''} ${
    value?.patronymic ?? ''
  }`;
}

export function phone(value) {
  // extend first pocket to match 7/8 in case phone numbers will
  // have format of 8(909)...
  return value
    ? value.replace(
        /^(\+7|)?(\d{3})(\d{3})(\d{2})(\d{2})/,
        (match, p0, p1, p2, p3, p4, offset, string) =>
          `+7 (${p1}) ${p2}-${p3}-${p4}`,
      )
    : '';
}

export function phoneSimple(value) {
  return value ? value.replace(/(-)|(\s)|(\()|(\))/g, '') : '';
}

export function dateTime(value) {
  if (!value) return '';
  const dt = DateTime.fromISO(value);
  return dt.toLocaleString(DateTime.DATETIME_MED);
}

export function date(value) {
  if (!value) return '';
  const dt = DateTime.fromISO(value);
  return dt.toLocaleString(DateTime);
}
