import Vue from 'vue';
import store from '@/store';

export function generateId() {
  return new Date().valueOf();
}

/**
 * Open component as modal
 * @param {*} component
 * @param {*} props
 * @param {*} listeners
 */
export function openModal(component, props, listeners) {
  const id = generateId();
  listeners = {
    close: () => closeModal(id),
    ...listeners,
  };

  // We wrap in state component object to function
  store.commit('addModal', {
    id,
    component: () => component,
    props,
    listeners,
  });
  return id;
}

/**
 * Close modal
 * @param {*} component
 * @param {*} props
 * @param {*} listeners
 */
export function closeModal(id) {
  store.commit('removeModal', id);
  return id;
}

const canPlugin = {
  install() {
    Vue.prototype.$openModal = openModal;
    Vue.prototype.$closeModal = closeModal;
  },
};

Vue.use(canPlugin);

export default canPlugin;
