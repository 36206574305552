import api from '@/api/config';
import { auth } from '@/utils/middlewares';
import inspection from '@/middleware/inspection';

export default {
  path: '/inspections',
  name: 'inspections',
  component: () => import('@/views/inspections/index.vue'),
  redirect: { name: 'inspections:list' },
  children: [
    {
      path: '',
      name: 'inspections:list',
      component: () => import('@/views/inspections/list.vue'),
      meta: {
        middleware: [auth('login', api.check)],
      },
    },
    {
      path: ':id',
      name: 'inspections:card',
      component: () => import('@/views/inspections/view.vue'),
      meta: {
        middleware: [auth('login', api.check), inspection],
      },
    },
  ],
};
