import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import apiInstance from '@/api/config';

import {
  vuelidate,
  notification,
  mask,
  middleware,
  FormGenerator,
} from './plugins';

import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

store.dispatch('AUTH/check');

new Vue({
  middleware,

  data: {
    isLoadingGlobal: false,
    isDisabledGlobal: false,
    loadingState: {},
  },

  computed: {
    isLoading() {
      return name => {
        return this.loadingState[name]?.isLoading || false;
      };
    },
    isDisabled() {
      return name => {
        return this.loadingState[name]?.isDisabled || false;
      };
    },
  },
  created() {
    /* eslint-disable no-console */
    console.groupCollapsed('Git Status while build');
    console.log('Commit:', process.env.VUE_APP_GIT_COMMIT);
    console.log('Branch:', process.env.VUE_APP_GIT_BRANCH);
    console.groupEnd();

    apiInstance.onLoading(
      function (state) {
        this.$root.isLoadingGlobal = state.isLoading;
        this.$root.isDisabledGlobal = state.isDisabled;
        this.$set(this.$root, 'loadingState', state.custom());
      }.bind(this),
    );

    this.$store.dispatch('initApplication');
  },

  router,
  store,
  vuelidate,
  notification,
  mask,
  vuetify,
  FormGenerator,
  render: h => h(App),
}).$mount('#app');
