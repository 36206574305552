import { VTextField, VSelect, VBtn, VRow, VCol, VDivider } from 'vuetify/lib';

export default {
  name: 'CameraVideoTiming',

  props: {
    schema: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      videoTiming: this.schema.default,
    };
  },

  methods: {
    onUpdate(idx, key, value) {
      if (idx && key && value) {
        this.videoTiming[idx][key] = value;
      }
      this.$emit('input', [...this.videoTiming]);
    },

    genSelect({ key, idx, label, value, list }) {
      return this.$createElement(VSelect, {
        props: {
          value,
          items: list,
          label,
          outlined: true,
        },
        on: {
          change: value => this.onUpdate(idx, key, value),
        },
      });
    },

    genTextField({ key, idx, label, value }) {
      return this.$createElement(VTextField, {
        props: {
          type: 'number',
          label,
          outlined: true,
          value: parseInt(value),
        },
        on: {
          input: value => this.onUpdate(idx, key, parseInt(value)),
        },
      });
    },

    genWrapForm(elements, idx) {
      const title = this.$createElement('h3', ['Камера ', idx + 1]);
      const divider = this.$createElement(VDivider, { staticClass: 'mb-3' });
      const wrap = (title, remove) => {
        const col = el =>
          this.$createElement(VCol, { props: { cols: 6 } }, [el]);
        const colEnd = el =>
          this.$createElement(
            VCol,
            { props: { cols: 6 }, staticClass: 'd-flex justify-end' },
            [el],
          );
        return this.$createElement(VRow, [col(title), colEnd(remove)]);
      };
      const removeBtn = this.$createElement(
        VBtn,
        {
          props: {
            rounded: true,
            text: true,
            small: true,
          },
          staticClass: 'blue--text',
          on: {
            click: this.onRemove(idx),
          },
        },
        ['Удалить'],
      );

      const content = elements.map(el => {
        const column = this.$createElement(VCol, { props: 12 }, [el]);
        return this.$createElement(VRow, [column]);
      });

      return this.$createElement('div', [
        wrap(title, removeBtn),
        divider,
        content,
      ]);
    },

    genContent() {
      return this.videoTiming.map((item, idx) => {
        const form = [];

        for (const [key, value] of Object.entries(item)) {
          let element = null;

          const label = this.schema.items.properties[key].title || '';

          switch (key) {
            case 'cameraName':
              element = this.genSelect({
                idx,
                key,
                label,
                value,
                list: this.schema.items.properties[key].enum || [],
              });
              break;

            case 'seconds':
              element = this.genTextField({
                idx,
                key,
                label,
                value,
              });
              break;

            default:
              break;
          }

          if (element) {
            form.push(element);
          }
        }

        return this.genWrapForm(form, idx);
      });
    },

    genAddBtn() {
      const btn = this.$createElement(
        VBtn,
        {
          props: {
            color: 'blue',
          },
          staticClass: 'white--text',
          on: {
            click: () => this.onAdd(),
          },
        },
        ['Добавить камеру'],
      );

      return this.$createElement(VRow, [
        this.$createElement(VCol, { props: { cols: 12 } }, [btn]),
      ]);
    },

    onAdd() {
      this.videoTiming.push({
        cameraName: 'frontCamera',
        seconds: 0,
      });
      this.onUpdate();
    },

    onRemove(idx) {
      return () => {
        this.videoTiming.splice(idx, 1);
        this.onUpdate();
      };
    },
  },

  render(h) {
    const title = this.$createElement('h2', [this.schema.title]);
    const description = this.$createElement('p', { staticClass: 'mb-3' }, [
      this.schema.description,
    ]);
    const content = this.genContent();
    const btn = this.genAddBtn();

    return h('div', [title, description, content, btn]);
  },
};
