import { integration } from '../config';

const IntegrationService = {
  message(payload) {
    return integration
      .loading(integration.post('/techsup', payload), 'support')
      .then(res => res.data);
  },
};

export default IntegrationService;
