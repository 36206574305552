import { VDivider } from 'vuetify/lib';
import { wrapper } from './utils';

export default [
  {
    name: 'ContainerLevel1',
    type: 'container',
    level: 1,
    pattern: [
      x => x.type === 'object',
      x => typeof x.title === 'string',
      x => typeof x.properties === 'object',
      x => !x.const,
      x => !x.default,
    ],
    valuePath: 'properties',
    component({ $h, $schema, $children }) {
      const title = $h('h1', $schema.title);
      const divider = $h(VDivider, { staticClass: 'mt-3 mb-5' });

      return [title, divider, $children()];
    },
  },

  {
    name: 'ContainerChildren',
    type: 'container',
    level: 2,
    pattern: [
      x => x.type === 'object',
      x => typeof x.properties === 'object',
      x => !x.title,
      x => !x.const,
      x => !x.default,
    ],
    valuePath: 'properties',
    component({ $children }) {
      return $children();
    },
  },

  {
    name: 'ContainerLevel2',
    type: 'container',
    level: 2,
    pattern: [
      x => x.type === 'object',
      x => typeof x.title === 'string',
      x => typeof x.properties === 'object',
      x => !x.const,
      x => !x.default,
    ],
    valuePath: 'properties',
    component({ $h, $schema, $children }) {
      return $h('div', [wrapper($h, $h('h3', [$schema.title])), $children()]);
    },
  },
];
