import api from '@/api';

const state = {
  items: [],
};

const getters = {
  versions: state => state.items,

  getIdVersionByName: state => name => {
    const findedVerions = state.items.filter(item => item.name === name);

    if (findedVerions.length !== 0) {
      return findedVerions[0].id;
    }

    return null;
  },
};

const mutations = {
  setVersions(state, versions) {
    state.items = versions;
  },
};

const actions = {
  async fetch({ commit, getters }) {
    if (getters.versions.length !== 0) {
      return getters.versions;
    }

    try {
      const { data } = await api('terminals/dictionary').versions();
      commit('setVersions', Object.freeze(data));
      return data;
    } catch (e) {
      return e;
    }
  },

  reset({ commit }) {
    commit('setVersions', []);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
