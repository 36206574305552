import Vue from 'vue';
import VueRouter from 'vue-router';
import { middlewareRegister } from '@frontend/middleware';
import { auth } from '@/utils/middlewares';
import api from '@/api/config';
import { can } from '@/plugins/can';
import { MODULES } from '@/utils/constants';

import inspectionsRoutes from './inspections';
import employeesRoutes from './employees';
import reportsRoutes from './reports';
import notificationsRoutes from './notifications';
import terminalsRoutes from './terminals';
import accountRoutes from './accounts';

Vue.use(VueRouter);

const routes = [
  ...middlewareRegister([
    {
      name: 'main',
      path: '/',
      redirect: { name: 'inspections' },
    },
    {
      path: '/organizations',
      name: 'organizations',
      component: () => import('@/views/Organizations.vue'),
      meta: {
        middleware: [auth('login', api.check)],
      },
    },
    inspectionsRoutes,
    employeesRoutes,
    reportsRoutes,
    notificationsRoutes,
    terminalsRoutes,
    accountRoutes,
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
    },
    {
      path: '/export',
      name: 'export',
      component: () => import('@/views/Export.vue'),
      meta: {
        middleware: [auth('login', api.check)],
      },
    },
    {
      path: '*',
      name: '404',
      component: () => import('@/views/NotFound.vue'),
    },
  ]),
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const hiddenModules = process.env.VUE_APP_HIDE_MODULES?.split(',') || [];

  if (MODULES.some(module => to.fullPath.includes(module.name))) {
    const allowedModules = MODULES.filter(
      module => !hiddenModules.includes(module.name) && can(module.right),
    );

    // if user has no allowed modules means he is most likely logged out
    // in this case redirect straight to /login page
    if (allowedModules.length === 0) next({ name: 'login' });
    // else if requested modules is in 'allowedModules' we redirect on it
    else if (allowedModules.some(module => to.fullPath.includes(module.name)))
      next();
    // otherwise we redirect to 1st available module
    else router.push({ name: allowedModules[0].name });
  } else next();
});

export default router;
