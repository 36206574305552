export default async function ({ to, $store, next, $notify }) {
  // await $store.dispatch(`TERMINAL_CREATE/getSchemas`).catch(() => {
  //   $notify({
  //     group: 'error',
  //     type: 'error',
  //     title: `Произошла ошибка загрузки схем конфигурации. Попробуйте позже.`,
  //     text: '',
  //   });
  // });

  next();
}
