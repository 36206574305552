<template lang="pug">
v-dialog(
  :value="true"
  max-width="450"
  persistent
)
  v-card
    v-card-title.d-flex.justify-space-between
      span Написать в поддержку.
      v-btn(
        icon
        @click="onAbort"
      )
        v-icon fa-times
    v-card-text
      p Пожалуйста заполните все поля.

      v-row
        v-col.py-0(cols="12")
          v-text-field(
            label="Тема вопроса"
            hint="Задайте свой вопрос или опишите проблему"
            v-model="form.subject"
            outlined
            depressed
            dense
            :disabled="$root.isLoading('support')"
            :error-messages="getValidationErrors('form.subject')"
          )
      v-row
        v-col.py-0(cols="12")
          v-text-field(
            label="Email"
            hint="Оставьте свой e-mail для обратной связи"
            v-model="form.email"
            outlined
            depressed
            dense
            :disabled="$root.isLoading('support')"
            :error-messages="getValidationErrors('form.email')"
          )
      v-row
        v-col.py-0(cols="12")
          v-text-field(
            label="Телефон"
            hint="Оставьте свой номер телефона, чтобы мы могли Вам перезвонить"
            v-model="form.phone"
            v-mask="'+7 (###) ###-##-##'"
            placeholder="+7 (___) ___-__-__"
            outlined
            depressed
            dense
            :disabled="$root.isLoading('support')"
            :error-messages="getValidationErrors('form.phone')"
          )
      v-row
        v-col.py-0(cols="12")
          v-text-field(
            label="Название компании"
            hint="Как называется ваша компания"
            v-model="form.company"
            outlined
            depressed
            dense
            :disabled="$root.isLoading('support')"
            :error-messages="getValidationErrors('form.company')"
          )
      v-row
        v-col.py-0(cols="12")
          v-textarea(
            label="Сообщение"
            v-model="form.question"
            outlined
            depressed
            dense
            :disabled="$root.isLoading('support')"
            :error-messages="getValidationErrors('form.question')"
          )

      v-row.py-0
        v-col.py-0.mt-0(cols="6")
          v-btn.mt-0(
            @click="onAbort"
            outlined
            height="40"
            color="primary"
            block
            :disabled="$root.isLoading('support')"
          ) Отмена
        v-col.py-0.mt-0(cols="6")
          v-btn.mt-0(
            @click="onSubmit"
            depressed
            height="40"
            color="primary"
            block
            :disabled="$root.isLoading('support')"
          ) Отправить
</template>

<script>
import api from '@/api';
import { mapGetters } from 'vuex';
import validationMixin from '@/utils/validation';
import { phone } from '@/utils/validators';
import { required, maxLength, email } from 'vuelidate/lib/validators';
import { phoneSimple } from '@/utils/convert/common';

export default {
  mixins: [validationMixin],

  data() {
    return {
      form: {
        subject: null,
        email: null,
        phone: null,
        company: null,
        login: null,
        question: null,
      },
    };
  },

  validations: {
    form: {
      subject: {
        required,
        maxSymbolsLength: maxLength(50),
      },
      email: { required, email },
      phone: {
        required,
        phone,
      },
      company: {
        required,
        maxSymbolsLength: maxLength(50),
      },
      question: {
        required,
        maxSymbolsLength: maxLength(500),
      },
    },
  },

  computed: {
    ...mapGetters('AUTH', ['currentUser']),
  },

  mounted() {
    this.form.login = this.currentUser.email;
    this.form.email = this.currentUser.email;
  },

  methods: {
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      const payload = Object.assign(this.form, {
        phone: phoneSimple(this.form.phone),
      });

      await api('integration')
        .message(payload)
        .then(data => {
          this.$notify({
            group: 'note',
            type: 'info',
            title: 'Ваше сообщение отправлено.',
            timeout: 4000,
          });

          this.reset();
          this.$v.$reset();
          this.$emit('close');

          return data;
        })
        .catch(error => {
          this.$emit('error', error);
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Ошибка. Попробуйте позже.',
            timeout: 4000,
          });
        });
    },

    onAbort() {
      this.$v.$reset();
      this.reset();
      this.$emit('close');
    },

    reset() {
      this.form.subject = null;
      this.form.email = null;
      this.form.phone = null;
      this.form.company = null;
      this.form.question = null;
    },
  },
};
</script>
