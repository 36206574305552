export const RIGHTS_MAP = {
  // Организации
  PREVIEW_ORGS: 'ORG_PR',
  READ_ORGS: 'ORG_R',
  EXPORT_ORGS: 'ORG_E',
  PREVIEW_HOSTS: 'HST_PR',

  // Медосмотры
  READ_INSPECTIONS: 'INS_R',
  READ_INSPECTIONS_ALL: 'INS_RA',
  READ_INSPECTIONS_DETAILED: 'INS_RD',
  EXPORT_INSPECTIONS: 'INS_E',
  READ_INSPECTION_TYPE: 'INST_R',
  DOWNLOAD_MEDRESULT: 'MEDR_L',
  DOWNLOAD_SIGNATURE: 'MEDS_L',
  PRINT_STICKERS: 'STKR_P',

  // Работники
  READ_EMPLOYEES: 'EMP_R',
  READ_EMPLOYEES_DETAILED: 'EMP_RD',
  CREATE_EMPLOYEES: 'EMP_C',
  UPDATE_EMPLOYEES: 'EMP_U',
  EXPORT_EMPLOYEES: 'EMP_E',
  IMPORT_EMPLOYEES: 'EMP_I',
  RESET_PASSWORD: 'EMP_RP',
  READ_EMPLOYEE_MEDDATA: 'EMP_RM',
  GET_EMPLOYEE_PHOTO: 'PHT_G',
  ARCHIVE_EMPLOYEE_PHOTO: 'PHT_A',

  // Медики
  READ_MEDIC_GROUPS: 'MG_R',

  // Отчеты
  READ_REPORTS: 'RPT_R',

  // Нотификации
  READ_NOTIFY_RECIPIENT: 'RCP_R',
  CREATE_NOTIFY_RECIPIENT: 'RCP_C',
  IMPORT_NOTIFY_RECIPIENT: 'RCP_I',
  DELETE_NOTIFY_RECIPIENT: 'RCP_D',
  BINDINGS_GET: 'HO_R',
  BINDINGS_ADD: 'HO_C',
  BINDINGS_EDIT: 'HO_U',
  BINDINGS_DROP: 'HO_D',

  // Терминал
  UPDATE_TERMINAL_STATUS: 'TMST_U', // Обновление статуса терминала.
  READ_TERMINAL_UPDATES: 'TMUP_R', // Получение апдейтов по терминалу.
  READ_TAGS: 'TTG_R', // Получение списка тегов для терминалов.
  MANIPULATE_RECIPES: 'RCPE_M', // Манипулирование рецептами.
  MANIPULATE_VERSIONS: 'VER_M', // Манипулирование версиями.
  READ_LICENSES: 'LC_R', // Получение списка лицензий.
  READ_VERSIONS: 'VER_R', // Получение списка версий.
  ISSUE_LICENSES: 'LC_C', // Выпуск новых лицензий.
  READ_TERMINALS_DETAILED: 'TM_RD', // Получение подробной информации по терминалам.
  UPDATE_TERMINALS: 'TM_U', // Изменение терминалов.
  MANAGE_TERMINALS_TAGS: 'TMTG_M', // Управление тегами терминалов.
  MIGRATE_TERMINALS: 'TM_MG', // Миграция терминала на другую версию.
  READ_TERMINALS: 'TM_R', // Получение списка терминалов.
  CREATE_TERMINALS: 'TM_C', // Создание терминалов.
  MANIPULATE_TAGS: 'TTG_M', // Манипулирование тегами.
  READ_RECIPES: 'RCPE_R', // Получение списка рецептов.
  REGISTER_TERMINALS: 'TM_RG', // Регистрация терминалов (через Foreman)

  // Аккаунты
  READ_ACCOUNTS: 'ACC_R',
};

export const MODULES = [
  {
    title: 'Осмотры',
    right: 'READ_INSPECTIONS',
    name: 'inspections',
  },
  {
    title: 'Работники',
    right: 'READ_EMPLOYEES',
    name: 'employees',
  },
  {
    title: 'Организации',
    right: 'READ_ORGS',
    name: 'organizations',
  },
  {
    title: 'Отчеты',
    right: 'READ_REPORTS',
    name: 'reports',
  },
  {
    title: 'Сервис подписи',
    right: 'SIGN_USERS',
    name: 'sign-service',
  },
  {
    title: 'Нотификации',
    right: 'READ_NOTIFY_RECIPIENT',
    name: 'notifications',
  },
  {
    title: 'Терминалы',
    right: 'READ_TERMINALS',
    name: 'terminals',
  },
  {
    title: 'Аккаунты',
    right: 'READ_ACCOUNTS',
    name: 'accounts',
  },
];

export const GENDER = {
  MALE: 'Мужской',
  FEMALE: 'Женский',
  undefined: 'Мужской',
};

export const STATUS = [
  {
    name: 'Все статусы',
    value: null,
  },
  {
    name: 'Активные',
    value: true,
  },
  {
    name: 'Неактивные',
    value: false,
  },
];

// --- INSPECTIONS ---

export const InspectionFailReasons = {
  ALCO: { name: 'алкоголь', color: '#e74c3c' }, // Красный
  MEDICAL: { name: 'медицинская', color: '#f1c40f' }, // Желтый
  FRAUD: { name: 'мошенничество', color: '#95a5a6' }, // Серый
  ADMINISTRATIVE: { name: 'административная', color: '#f39c12' }, // Оранжевый
  BREAK: { name: 'прерван', color: '#9b59b6' }, // Фиолетовый
  TECHNICAL: { name: 'техническая', color: '#287BD8' }, // синий
  undefined: { name: '', color: '#2980b9' },
};

export const InspectionReactionType = [
  { type: 'NUMBERS', name: 'Шульте' },
  { type: 'DOTS', name: 'Тахистоскопия' },
];

export const InspectionResolutions = {
  BEFORE: { true: 'Допущен', false: 'Не допущен' },
  BEFORE_SHIFT: { true: 'Допущен', false: 'Не допущен' },
  LINE: { true: 'Прошел', false: 'Отстранен' },
  AFTER: { true: 'Прошел', false: 'Не прошел' },
  AFTER_SHIFT: { true: 'Прошел', false: 'Не прошел' },
  ALCO: { true: 'Прошел', false: 'Не прошел' },
  PREVENTION: { true: 'Прошел', false: 'Не прошел' },
  PIRO: { true: 'Прошел', false: 'Не прошел' },
  undefined: { true: 'Нет данных', false: 'Нет данных' },
};

export const InspectionTypes = {
  BEFORE: 'Предрейсовый',
  BEFORE_SHIFT: 'Предсменный',
  LINE: 'Линейный',
  AFTER: 'Послерейсовый',
  AFTER_SHIFT: 'Послесменный',
  ALCO: 'Алкотестирование',
  PREVENTION: 'Профилактический',
  PIRO: 'Контроль температуры',
  undefined: 'Неизвестный тип',
};

// --- REPORTS ---

export const REPORTS = {
  // Меджурнал
  'med-journal': {
    form: 'MedJournalForm',
    filters: 'medjournal',
    priority: 1,
    description:
      'Журнал регистрации предрейсовых и послерейсовых медицинских осмотров. Содержит результаты прохождения осмотров водителей и рабочих компании согласно требований Приказа Минздрава России от 15.12.2014 N 835н.',
  },

  // Статистика
  'statistics-views-count': {
    form: 'StatsForm',
    availableFormats: ['csv', 'json'],
    chart: 'CountChart',
    fields: [
      'orgIds',
      'dateRange',
      'inspectionTypes',
      'includeTechErrors',
      'includeTest',
    ],
    priority: 5,
    description:
      'Отчет включает ежедневные итоги по количеству осмотров с группировкой их по типам осмотров и по причинам недопуска.',
  },
  'statistics-views-avg-time': {
    form: 'StatsForm',
    availableFormats: ['csv', 'json'],
    chart: 'TimeChart',
    fields: [
      'orgIds',
      'dateRange',
      'inspectionTypes',
      'includeTechErrors',
      'includeTest',
    ],
    priority: 7,
    description: '',
  },

  // Отчеты
  'user-actions-log-arm': {
    form: 'GeneratedForm',
    fields: ['dateRange', 'orgId'],
    priority: 6,
    description:
      'Отчет содержит данные логирования действий пользователей по добавлению, изменению данных в АРМ Диспетчера.',
  },
  'small-months': {
    form: 'GeneratedForm',
    fields: ['dateRange', 'orgId', 'includeTechErrors', 'includeTest'],
    priority: 17,
    description: '',
  },
  'small-day-d': {
    form: 'GeneratedForm',
    fields: ['dateRange', 'orgId', 'includeTechErrors', 'includeTest'],
    priority: 4,
    description:
      'В отчете собраны ежедневные итоги по количеству осмотров с разделением их на допуски / недопуски и группировкой по причинам недопуска.',
  },
  'insp-by-org-full': {
    form: 'GeneratedForm',
    fields: ['dateRange', 'orgId', 'includeTechErrors', 'includeTest'],
    priority: 3,
    description:
      'Отчет включает данные о каждом осмотре с указанием комментариев от медработника,  расчетом длительности осмотра с раскрытием медицинских показателей работников.',
  },
  'prevention-by-org': {
    form: 'GeneratedForm',
    fields: ['dateRange', 'orgId', 'includeTest'],
    priority: 12,
    description: '',
  },
  'views-deviations': {
    form: 'GeneratedForm',
    fields: ['dateRange', 'timeRange'],
    priority: 9,
    description: '',
  },
  'views-per-hour': {
    form: 'GeneratedForm',
    fields: ['dateRange', 'timeRange', 'region'],
    priority: 18,
    description: '',
  },
  'risk-groups': {
    form: 'GeneratedForm',
    fields: ['orgId'],
    priority: 11,
    description: '',
  },

  // Отчеты служебные
  'company-stats': {
    form: 'GeneratedForm',
    fields: ['dateRange'],
    priority: 19,
    description: '',
  },
  rejects: {
    form: 'GeneratedForm',
    fields: ['dateRange'],
    priority: 8,
    description: '',
  },
  'avg-med-reaction': {
    form: 'GeneratedForm',
    fields: ['dateRange'],
    priority: 20,
    description: '',
  },
  'medics-full': {
    form: 'GeneratedForm',
    fields: ['dateRange'],
    priority: 21,
    description: '',
  },
  'medics-small': {
    form: 'GeneratedForm',
    fields: ['dateRange', 'region'],
    priority: 22,
    description: '',
  },
  'tp-stats-views': {
    form: 'GeneratedForm',
    fields: ['dateRange', 'includeTechErrors'],
    priority: 2,
    description:
      'Отчет содержит информацию о каждом осмотре с указанием комментариев от медработника и расчетом длительности осмотра, но без раскрытия медицинских показателей.',
  },
  'notification-journal': {
    form: 'GeneratedForm',
    fields: ['dateRange', 'orgId'],
    priority: 10,
    description: '',
  },

  // Пустые отчеты
  'mgt-1c-intergration': {
    form: 'EmptyForm',
    priority: 14,
    description: '',
  },
  'find-face-employees': {
    form: 'EmptyForm',
    priority: 15,
    description: '',
  },
  'find-face-organization': {
    form: 'EmptyForm',
    priority: 16,
    description: '',
  },
};

// --- VALIDATIONS ---

export const VALIDATION_RULE_MESSAGES = {
  required: 'Обязательно',
  minSymbolsLength: 'Не менее $min символов',
  maxSymbolsLength: 'Не больше $max символов',
  minAge: 'Возраст не меньше $min лет',
  maxAge: 'Возраст не больше $max лет',
  fileImageOrPath: 'Только ".png", ".jpg", ".jpeg"',
  driverLicense: '10 символов, кирилица, латиница и цифры',
  email: 'Только валидная почта',
  phone: 'Телефон в формате +7 (000) 000-00-00',
  date: 'Дата не валидна',
  cyrillicName: 'Только кирилица, минимум 1 символ',
  fileCsv: 'Только ".csv"',
  contact: 'Контакт не валиден',
  integer: 'Только целое число',
};

export const USER_GUIDE_URL = {
  terminal:
    process.env.VUE_APP_INTEGRATION +
    '/static/dispatcher/docs/user_guide_terminal.pdf',
  dispatcher:
    process.env.VUE_APP_INTEGRATION +
    '/static/dispatcher/docs/user_guide_dispatcher.pdf',
};
