import { VRow, VCol } from 'vuetify/lib';

export const wrapper = ($h, $children) => {
  return $h(VRow, [$h(VCol, { props: { cols: 12 } }, [$children])]);
};

export const isDisabled = ({ edit }, role = '') => {
  if (typeof edit === 'undefined') {
    return false;
  }

  if (!Array.isArray(edit) || edit.length === 0) {
    return true;
  }

  return edit.includes(role);
};

export const errorMessage = function (key) {
  const schemas = Array.prototype.slice.call(arguments, 1);
  const length = schemas.length;
  const message = { errorMessage: 'Ошибка' };

  if (!key || schemas.length === 0) {
    return message;
  }

  for (let i = 0; i < length; ++i) {
    if (schemas[i] && schemas[i].errors && schemas[i].errors[key]) {
      message.errorMessage = schemas[i].errors[key];
      break;
    }
  }

  return message;
};
