import dictionary from './dictionary';
import schemas from './schemas';
import tags from './tags';
import terminals from './terminals';

export default {
  dictionary,
  schemas,
  tags,
  terminals,
};
