import { VCheckbox } from 'vuetify/lib';
import { wrapper, isDisabled } from './utils';

export default [
  {
    name: 'Checkbox',
    type: 'control',
    pattern: [x => x.type === 'boolean', x => typeof x.title === 'string'],
    valuePath: 'default',
    component({ $h, $schema, $value }) {
      const description = $schema.description
        ? { messages: $schema.description }
        : {};
      const content = $h(VCheckbox, {
        props: {
          'input-value': $value(),
          label: $schema.title,
          disabled: isDisabled($schema),
          falseValue: false,
          trueValue: true,
          ...description,
        },
        on: {
          change: value => $value(value),
        },
      });

      return wrapper($h, content);
    },
  },
];
