import auth from '@/api/config';

const state = {
  testCredentials: [],
  isAuthorized: false,
  currentUser: {},
  rights: [],
};

const getters = {
  testCredentials: state => state.testCredentials,
  currentUser: state => state.currentUser,
  isAuthorized: state => state.isAuthorized,
  softAuth: state => !state.isAuthorized && !!state.currentUser.email,

  rights: state => state.rights,
  can: state => right => state.rights.includes(right),
};

const mutations = {
  testCredentials: (state, value) => (state.testCredentials = value),
  currentUser: (state, value) => (state.currentUser = value),
  rights: (state, value) => (state.rights = value),
  isAuthorized: (state, value) => (state.isAuthorized = value),
};

const actions = {
  init({ commit }) {
    // For comfortable development
    const credentials = (process.env.VUE_APP_TEST_CREDENTIALS || '')
      .split(';')
      .filter(item => item)
      .map(item => item.split(':'));

    commit('testCredentials', credentials);
  },

  async signIn(_, { username, password, recovery }) {
    try {
      await auth.loading(
        auth.signIn(
          { login: username, password },
          recovery ? 'recovery_session' : null,
        ),
      );
    } catch (error) {
      if (error.response?.status === 400) {
        throw error;
      } else if (error.response?.status === 401) {
        throw new Error('Неверный пароль или логин');
      } else if (error.response?.status === 403) {
        throw new Error('Нет прав доступа');
      } else if (error.response?.status === 404) {
        throw new Error('Пользователя с такой почтой не существует');
      } else if (error.message === 'Network Error') {
        throw new Error(
          'Ошибка сети. Проверьте интернет соеденение или обратитесь в ТП',
        );
      }
      throw new Error('Произошла ошибка авторизации');
    }
  },
  async signOut() {
    await auth.signOut();
    localStorage.removeItem('rights');
    document.location.reload();
  },

  check({ commit, dispatch }) {
    // Restore user and rights after page refresh
    const rights = localStorage.getItem('rights')?.split(',');
    const softAuth = rights?.length > 0;
    if (rights) {
      commit('rights', rights);
      commit('isAuthorized', true);
      commit('currentUser', { email: auth.loginName() });
    }

    // NOTE:this part is related to igor's authorization plugin
    // don't try to understand it
    auth.onAuthorized((_, payload) => {
      try {
        const rights = payload.access.actions;
        // Update user rights
        commit('rights', rights);
        commit('isAuthorized', true);
        localStorage.setItem('rights', rights);

        // Reinit application after user sign in
        !softAuth && dispatch('initApplication', {}, { root: true });
      } catch (e) {
        console.warn(e);
      }
    });

    auth.onUnauthorized(payload => {
      commit('isAuthorized', false);
      if (payload?.response.status !== 401) {
        auth.signOut();
        commit('rights', []);
        localStorage.removeItem('rights');
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
