import { dispatcher } from '../config';

const MedicService = {
  getGroupTypes() {
    return dispatcher.get('/medics/groups/types').then(res => res.data.data);
  },
  getGroups(typeId) {
    const query = Array.isArray(typeId)
      ? '?ids=' + typeId.join()
      : '/' + (typeId || '');
    return dispatcher.get('/medics/groups' + query).then(res => res.data.data);
  },
};

export default MedicService;
