import { terminals } from '../../config';

const TerminalsRepository = {
  terminals(params) {
    return terminals
      .loading(terminals.get('/terminals', { params }), 'terminals_list')
      .then(res => res.data);
  },

  getById(id) {
    return terminals
      .loading(terminals.get(`/terminals/${id}`))
      .then(res => res.data);
  },

  update(terminal) {
    return terminals.loading(
      terminals.put('/terminals', terminal),
      'update_terminal',
    );
  },
};

export default TerminalsRepository;
