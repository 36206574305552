import api from '@/api';

const state = {
  query: {
    order: 'id',
    orderType: false,
    page: 1,
    limit: 50,
    // filters
    orgId: null,
    hostId: null,
    typeIds: null,
    groupIds: null,
    active: true,
    contactId: null,
  },
  total: 0,
  items: [],

  orgType: 'org',
  editItem: {},
  listSearchContact: [],
  selectedContact: { id: null, content: '' },
};

const getters = {
  total: state => state.total,
  query: ({ query }, getters, rootState, rootGetters) => {
    return {
      orgId: getters.orgType === 'org' && query.orgId ? query.orgId : null,
      hostId: getters.orgType === 'host' && query.hostId ? query.hostId : null,
      typeIds:
        query.typeIds?.length &&
        query.typeIds.length !== rootGetters['NOTIFICATIONS/typesNotif'].length
          ? query.typeIds.join(',')
          : null,
      groupIds:
        query.groupIds?.length &&
        query.groupIds.length !== rootGetters['NOTIFICATIONS/groupList'].length
          ? query.groupIds.join(',')
          : null,
      active: query.active,
      contactId: query.contactId,
      orderType: query.orderType ? 'DESC' : 'ASC',
    };
  },
  limit: state => state.query.limit,
  order: state => state.query.order,
  orderType: state => state.query.orderType,
  currentPage: state => state.query.page,
  totalPages: state =>
    !state.total ? 0 : Math.ceil(state.total / state.query.limit),
  isShowPagination: state => state.total / state.query.limit > 1,
  items: (state, getters, rootState, rootGetters) => {
    return state.items.map((item, index) => {
      item.id = index;
      const typesMap = rootGetters['NOTIFICATIONS/typesMap'];
      item.typeNames = {
        active: item.typeIds.active.map(item => typesMap[item]),
        notActive: item.typeIds.notActive.map(item => typesMap[item]),
      };
      return item;
    });
  },

  // filter logic
  selectedOrgId: state => state.query.orgId,
  selectedHostId: state => state.query.hostId,
  selectedTypes: state => state.query.typeIds,
  selectedGroups: state => state.query.groupIds,
  status: state => state.query.active,
  search: state => state.query.contactId,
  orgType: state => state.orgType,
  editItem: state => state.editItem,
  listSearchContact: state => state.listSearchContact,
  selectedContact: state => state.selectedContact,
};

const mutations = {
  orderBy: (state, value) => (state.query.order = value),
  orderType: (state, value) => (state.query.orderType = value),
  items: (state, value) => (state.items = value),
  total: (state, value) => (state.total = value),
  limit: (state, value) => (state.query.limit = value),
  currentPage: (state, value) => (state.query.page = value),
  resetCurrentPage: state => (state.query.page = 1),

  orgId(state, value) {
    state.query.orgId = value;
  },
  selectedOrgId(state, value) {
    state.query.orgId = value;
  },
  selectedHostId(state, value) {
    state.query.hostId = value;
  },
  initGroups(state, value) {
    state.query.groupIds = value;
  },
  selectedGroups(state, value) {
    state.query.groupIds = value;
  },
  initTypes(state, value) {
    state.query.typeIds = value;
  },
  selectedTypes(state, value) {
    state.query.typeIds = value;
  },
  status(state, value) {
    state.query.active = value;
  },
  search: (state, value) => {
    state.query.contactId = value;
  },
  orgType(state, value) {
    state.orgType = value;
  },
  editItem(state, value) {
    state.editItem = value;
  },
  listSearchContact(state, value) {
    state.listSearchContact = value;
  },
  selectedContact: (state, data) => {
    state.selectedContact = data;
  },
  resetFilters(state, value) {
    state.query.orgId = null;
    state.query.hostId = null;
    state.query.groupIds = value.groupIds;
    state.query.typeIds = value.typeIds;
    state.query.active = true;
    state.query.contactId = null;
    state.selectedContact = { id: null, content: '' };
    state.listSearchContact = [];
  },
};

const actions = {
  init({ commit, dispatch }) {
    [
      'selectedOrgId',
      'selectedHostId',
      'selectedGroups',
      'selectedTypes',
      'status',
      'limit',
    ].forEach(mutation => {
      this.$subscribeOn(`NOTIFICATIONS_LIST/${mutation}`, () =>
        commit('resetCurrentPage'),
      );
    });

    // load new bindings when any of these r triggered
    [
      'selectedOrgId',
      'selectedHostId',
      'selectedGroups',
      'selectedTypes',
      'status',
      'orderBy',
      'orderType',
      'limit',
      'currentPage',
    ].forEach(mutation => {
      this.$subscribeOn(`NOTIFICATIONS_LIST/${mutation}`, () =>
        dispatch('fetchList'),
      );
    });
  },

  async fetchList({ getters, state, commit }, refresh = true) {
    if (!refresh && getters.items.length) {
      return { data: getters.items, total: state.total };
    }

    commit('total', 0);
    commit('items', []);
    const { data, total } = await api('notifications').getRecipients(
      getters.query,
    );

    commit('items', data);
    commit('total', total);
    return { data, total };
  },

  resetFilters({ commit, getters, rootGetters }) {
    const defaultValue = {
      typeIds: rootGetters['NOTIFICATIONS/typeIds'],
      groupIds: rootGetters['NOTIFICATIONS/groupIds'],
    };
    commit('resetFilters', defaultValue);
    commit('currentPage', 1);
  },

  async querySearchContact({ commit }, value) {
    try {
      const data = await api('notifications').searchContact(value);
      commit('listSearchContact', data);
    } catch (error) {
      commit('listSearchContact', []);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
