import subscribePlugin from '@/store/plugins/subscribePlugin';
import Vue from 'vue';
import Vuex from 'vuex';

import ROOT from './root';
import AUTH from './modules/auth';
import INSPECTION from './modules/inspection';
import INSPECTION_LIST from './modules/inspection-list';
import EMPLOYEE from './modules/employee';
import EMPLOYEE_LIST from './modules/employee-list';
import ORGANIZATIONS from './modules/organizations';
import REPORTS from './modules/reports';
import NOTIFICATIONS from './modules/admin-notifications/notifications';
import NOTIFICATIONS_ADD from './modules/admin-notifications/notifications-add';
import NOTIFICATIONS_LIST from './modules/admin-notifications/notifications-list';
import TERMINALS_ORGANIZATIONS from './modules/terminals/organizations';
import TERMINALS_TAGS from './modules/terminals/tags';
import TERMINALS_VERSIONS from './modules/terminals/version';
import TERMINAL_CREATE from './modules/terminals/terminal-create';
import TERMINAL_UPDATE from './modules/terminals/terminal-update';
import TERMINALS_FILTER from './modules/terminals/terminals-filter';
import TERMINALS_LIST from './modules/terminals/terminals-list';
import BINDINGS from './modules/admin-notifications/bindings';
import ACCOUNTS from './modules/accounts';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [subscribePlugin],
  modules: {
    AUTH,
    INSPECTION,
    INSPECTION_LIST,
    EMPLOYEE,
    EMPLOYEE_LIST,
    ORGANIZATIONS,
    REPORTS,
    NOTIFICATIONS,
    NOTIFICATIONS_ADD,
    NOTIFICATIONS_LIST,
    TERMINALS_ORGANIZATIONS,
    TERMINALS_TAGS,
    TERMINALS_VERSIONS,
    TERMINAL_CREATE,
    TERMINAL_UPDATE,
    TERMINALS_FILTER,
    TERMINALS_LIST,
    BINDINGS,
    ACCOUNTS,
  },
  ...ROOT,
});
