<template lang="pug">
v-btn.goto(
  :class="{'goto--active': isActive}"
  :fixed="isActive"
  bottom
  right
  fab
  dark
  large
  color="primary"
  @click="$vuetify.goTo(0)"
)
  v-icon(dark size="26") fa-arrow-up

</template>

<script>
export default {
  data() {
    return {
      isActive: false,
      throttle: null,
    };
  },
  created() {
    window.addEventListener('scroll', this.checkOffset);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.checkOffset);
  },

  methods: {
    checkOffset(ms) {
      this.isActive = window.pageYOffset > 200;
    },

    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style lang="sass">
.goto
  display: none

  &--active
    display: block
</style>
