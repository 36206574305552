import { terminals } from '../../config';

const TagsManagerRepository = {
  add(terminalId, data) {
    return terminals
      .loading(terminals.post(`/terminals/${terminalId}/tags`, data), 'tags')
      .then(res => res.data);
  },

  delete(terminalId, tagId) {
    return terminals
      .loading(
        terminals.delete(`/terminals/${terminalId}/tags/${tagId}`),
        'tags',
      )
      .then(res => res.data);
  },
};

export default TagsManagerRepository;
