import { name } from './common';

export function convertAccount(account) {
  return {
    id: account.id,
    type: account.type,
    email: account.email,
    name: name(account.profile),
    organization: account.profile.organization || '-',
    position: account.profile.position || '–',
    isEnabled: isEnabled(account.isEnabled),
    roleId: account.roleId,
  };
}

export function isEnabled(value) {
  return value ? 'Активный' : 'Неактивный';
}
