export const LIST_STATUSES = ['not_loaded', 'loading', 'loaded', 'error'];

export default function fetchListFactory({ fetchMethod, filters } = {}) {
  const fileterKeys = Object.keys(filters || {});
  const filtersGetters = Object.fromEntries(
    fileterKeys.map(name => [name, state => state.listQuery[name]]),
  );
  const filtersMutations = Object.fromEntries(
    fileterKeys.map(name => [
      name,
      (state, value) =>
        (state.listQuery = { ...state.listQuery, [name]: value, page: 1 }),
    ]),
  );

  return {
    state: {
      // items
      listItems: [],
      listStatus: LIST_STATUSES[0],
      listError: null,
      listTotal: 0,

      // query
      listQuery: {
        ...filters,
        page: 1,
        limit: 50,
        orderBy: 'id',
        orderType: false,
        search: '',
      },
    },
    getters: {
      // items
      listItems: state => state.listItems,
      listTotal: state => state.listTotal,
      listIsLoading: state =>
        state.listStatus === LIST_STATUSES[0] ||
        state.listStatus === LIST_STATUSES[1],
      listItem: state => id => state.listItems.find(item => item.id === id),

      // query
      listLimit: state => state.listQuery.limit,
      listSearch: state => state.listQuery.search,
      listOrderBy: state => state.listQuery.orderBy,
      listOrderType: state => state.listQuery.orderType,
      listOrder: state => ({
        orderType: state.listQuery.orderType ? 'DESC' : 'ASC',
        orderBy: state.listQuery.orderBy,
      }),
      listCurrentPage: state => state.listQuery.page,
      listQuery: (state, getters) => ({
        ...state.listQuery,
        ...getters.listOrder,
        search: state.listQuery.search || null,
      }),
      ...filtersGetters,

      // page count
      listTotalPages: state =>
        !state.listTotal
          ? 0
          : Math.ceil(state.listTotal / state.listQuery.limit),
      listShowPagination: state => state.listTotal / state.listQuery.limit > 1,
    },
    mutations: {
      // items
      listFetching: state => {
        state.listItems = [];
        state.listTotal = null;
        state.listStatus = LIST_STATUSES[1];
        state.listError = null;
      },
      listFetched: (state, { data, total }) => {
        state.listItems = data || [];
        state.listTotal = total || 0;
        state.listStatus = LIST_STATUSES[2];
        state.listError = null;
      },
      listErrorFetched: (state, error) => {
        state.listItems = [];
        state.listTotal = null;
        state.listStatus = LIST_STATUSES[3];
        state.listError = error;
      },
      listAddItem: (state, value) => {
        state.listItems = [value, ...state.listItems];
        state.listTotal += 1;
      },
      listUpdateItem: (state, value) => {
        const index = state.listItems.findIndex(item => item.id === value.id);
        state.listItems = [
          ...state.listItems.slice(0, index),
          { ...state.listItems[index], ...value },
          ...state.listItems.slice(index + 1),
        ];
      },
      listRemoveItem: (state, id) => {
        const index = state.listItems.findIndex(item => item.id === id);
        state.listItems = [
          ...state.listItems.slice(0, index),
          ...state.listItems.slice(index + 1),
        ];
        state.listTotal -= 1;
      },

      // query
      listQuery: (state, value) =>
        (state.listQuery = { ...state.listQuery, ...value }),
      listOrderBy: (state, value) =>
        (state.listQuery = { ...state.listQuery, orderBy: value }),
      listOrderType: (state, value) =>
        (state.listQuery = { ...state.listQuery, orderType: value }),
      listSearch: (state, value) =>
        (state.listQuery = { ...state.listQuery, search: value, page: 1 }),
      listLimit: (state, value) =>
        (state.listQuery = { ...state.listQuery, limit: value, page: 1 }),
      listCurrentPage: (state, value) =>
        (state.listQuery = { ...state.listQuery, page: value }),
      listResetPage: state =>
        (state.listQuery = { ...state.listQuery, page: 1 }),
      listResetFilters: state => {
        state.listQuery = {
          ...filters,
          page: 1,
          limit: 50,
          orderBy: 'id',
          orderType: false,
          search: '',
        };
      },
      ...filtersMutations,
    },
    actions: {
      fetchList: async ({ commit, getters }, query) => {
        if (query) commit('listQuery', query);
        try {
          commit('listFetching');
          const response = await fetchMethod(getters.listQuery);
          commit('listFetched', response);
        } catch (error) {
          commit('listErrorFetched', error);
          throw error;
        }
      },
      querySearchList({ commit, getters }, search) {
        search = search?.trim() || '';
        const fieldWasCleared =
          search.length === 0 && search !== getters.listSearch;

        if (
          (search.length < 2 && !fieldWasCleared) ||
          search === getters.listSearch
        )
          return;
        commit('listSearch', search);
        commit('listResetPage');
      },
      resetListFilters: ({ commit }) => {
        commit('listResetFilters');
      },
    },
  };
}
