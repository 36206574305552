import api from '@/api';
import fetchListFactory from '@/utils/vuex/fetchListFactory';
import { createModule } from '@/utils/vuex/createModule';
import { convertAccount } from '@/utils/convert/account';

const state = {
  roles: [],
};

const getters = {
  listItems: state => (state.listItems || []).map(convertAccount),
  roles: state => state.roles,
  role: state => (id, field) => {
    const role = state.roles.find(role => role.id === id);
    if (!role) return 'id' + id; // is it the correct thing to return? unknown for now
    if (!field) return role;
    else return role[field];
  },

  itemBindings: (_, getters) => id => ({
    orgs:
      getters.listItem(id)?.bindings?.orgs?.map(({ targetId }) => targetId) ||
      [],
  }),

  listQuery: state => ({
    ...state.listQuery,
    // FIXME: why it even is here
    search: state.listQuery.search || null,
    type: 'basic',
  }),
};

const mutations = {
  roles: (state, value) => (state.roles = value),
  updateBindings: (state, { id, bindings }) => {
    const index = state.listItems.findIndex(item => item.id === id);
    const account = state.listItems[index];
    state.listItems = [
      ...state.listItems.slice(0, index),
      {
        ...account,
        bindings: { orgs: [...account.bindings.orgs, ...bindings.orgs] },
      },
      ...state.listItems.slice(index + 1),
    ];
  },
  removeBinding: (state, { id, bindingType, bindingId }) => {
    const index = state.listItems.findIndex(item => item.id === id);
    const account = state.listItems[index];
    const bindingIndex = account.bindings[bindingType].findIndex(
      item => item.id === bindingId,
    );
    state.listItems = [
      ...state.listItems.slice(0, index),
      {
        ...account,
        bindings: {
          [bindingType]: [
            ...account.bindings[bindingType].slice(0, bindingIndex),
            ...account.bindings[bindingType].slice(bindingIndex + 1),
          ],
        },
      },
      ...state.listItems.slice(index + 1),
    ];
  },
};

const actions = {
  async fetchRoles({ commit }) {
    const reponse = await api('accounts').roles();
    commit('roles', reponse ?? []);
  },

  async fetchItem({ commit }, id) {
    const result = await api('accounts').get(id);
    commit('listUpdateItem', result);
  },

  async createItem({ commit }, payload) {
    const bindings = Object.entries(payload.bindings).map(([key, item]) => ({
      type: key,
      ids: item,
    }));
    const account = await api('accounts').create({ ...payload, bindings });
    commit('listAddItem', account);
  },

  async changeStatus({ commit }, { id, isEnabled }) {
    const action = isEnabled ? 'enable' : 'disable';

    await api('accounts')[action](id);
    commit('listUpdateItem', { id, isEnabled });
  },

  async changeRole({ commit }, { id, roleId }) {
    await api('accounts').updateRole(id, roleId);
    commit('listUpdateItem', { id, roleId });
  },

  async changeProfile({ commit }, { id, profile }) {
    await api('accounts').profile({ id, profile });
    commit('listUpdateItem', { id, profile });
  },

  changeBindings({ commit, getters }, { id, targetIds, bindingType }) {
    let newBindings = [...targetIds];
    const promises = [];
    const accountBindings = getters.listItem(id)?.bindings[bindingType] || [];

    // we go around array of existing account bindings
    accountBindings.map(({ id: bindingId, targetId }) => {
      // if this binding not exists in array of new bindings, removes it
      const index = newBindings.indexOf(targetId);
      if (index === -1) {
        promises.push(
          api('accounts')
            .deleteBinding(bindingId)
            .then(_ => commit('removeBinding', { id, bindingType, bindingId })),
        );
      } else {
        // if this binding exists in array of new bindings,
        // excludes it from array of new bindings
        delete newBindings[index];
      }
    });
    newBindings = newBindings.filter(id => id);

    // if we have more than 0 new bindings, updates them
    if (newBindings.length > 0)
      promises.push(
        api('accounts')
          .addBindings(id, [{ type: bindingType, ids: newBindings }])
          .then(ids =>
            commit('updateBindings', { id, bindings: { [bindingType]: ids } }),
          ),
      );

    return Promise.all(promises);
  },

  async resetPassword(_, id) {
    await api('accounts').resetPassword(id);
  },

  async delete({ commit }, id) {
    await api('accounts').delete(id);
    commit('listRemoveItem', id);
  },
};

export default createModule(
  fetchListFactory({
    fetchMethod: api('accounts').list,
    filters: {
      roleId: null,
      isEnabled: true,
    },
  }),
  { state, getters, mutations, actions },
);
