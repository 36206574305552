import api from '@/api';
import fetchListFactory from '@/utils/vuex/fetchListFactory';
import { createModule } from '@/utils/vuex/createModule';
import { convertOrganization } from '@/utils/convert/organization';

const getters = {
  listItems: state => (state.listItems || []).map(convertOrganization),
};

const actions = {
  export(_, query) {
    return api('organizations').export(query);
  },
};

export default createModule(
  fetchListFactory({
    fetchMethod: api('organizations').list,
  }),
  { getters, actions },
);
