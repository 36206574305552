import api from '../../../api';

const state = {
  currentArray: [],
  orgList: [],
  hostList: [],
  types: [],
  groups: [],
  savedTableData: [],
};

const getters = {
  currentArray: state => state.currentArray,
  orgList: state => state.orgList,
  orgIds: state => {
    return state.orgList.map(item => item.id);
  },
  hostList: state => state.hostList,
  hostIds: state => {
    return state.hostList.map(item => item.id);
  },
  hostNames: state => {
    return state.hostList.map(item => item.name);
  },
  typesNotif: state => state.types,
  typeIds: state => {
    return state.types.map(item => item.id);
  },
  typeNames: state => {
    return state.types.map(item => {
      const val = { id: item.id, name: item.reason };
      return val;
    });
  },
  typesMap: state => {
    return state.types.reduce((typesMap, item) => {
      return Object.assign(typesMap, { [item.id]: item.reason });
    }, {});
  },
  groupList: state => state.groups,
  groupIds: state => {
    return state.groups.map(item => item.id);
  },
  savedTableData: state => state.savedTableData,
};

const mutations = {
  currentArray(state, value) {
    state.currentArray = value.map(item => {
      // если послать в запросе типы, то реципиент не удалиться
      delete item.typeIds;
      return item;
    });
  },
  orgList(state, value) {
    state.orgList = value;
  },
  hostList(state, value) {
    state.hostList = value;
  },
  types: (state, types) => {
    state.types = types;
  },
  groups: (state, groups) => {
    state.groups = groups;
  },
  savedTableData(state, value) {
    state.savedTableData = value;
  },
};

const actions = {
  async getTypes({ commit, getters, rootState }) {
    try {
      const data = await api('notifications').types();
      commit('types', data);
      commit('NOTIFICATIONS_LIST/initTypes', getters.typeIds, {
        root: true,
      });
    } catch (error) {
      commit('types', []);
      throw error;
    }
  },

  async getGroups({ commit, getters, rootState }) {
    try {
      const data = await api('notifications').groups();
      commit('groups', data);
      commit('NOTIFICATIONS_LIST/initGroups', getters.groupIds, {
        root: true,
      });
      commit('BINDINGS/initGroups', getters.groupIds, {
        root: true,
      });
    } catch (error) {
      commit('groups', []);
      throw error;
    }
  },

  async queryOrgList({ getters, commit, rootState }, refresh) {
    if (!refresh && getters.orgList.length !== 0) return getters.orgList;

    try {
      const data = await api('notifications').getOrgs();
      commit('orgList', data);
      commit('BINDINGS/initOrgs', getters.orgIds, { root: true });
      return data;
    } catch (error) {
      commit('orgList', []);
      throw error;
    }
  },

  async queryHostList({ getters, commit, rootState }, value) {
    if (getters.hostList.length !== 0) return getters.hostList;
    try {
      const data = await api('notifications').getHosts();
      commit('hostList', data);
      commit('BINDINGS/initHosts', getters.hostIds, { root: true });
      return data;
    } catch (error) {
      commit('hostList', []);
      throw error;
    }
  },

  async edit({ getters, dispatch }, payload) {
    try {
      const data = await api('notifications').edit(payload);
      return data;
    } catch (error) {
      console.warn('error edit', error);
      throw error;
    }
  },

  // use in ExcelTable
  deleteMass({ commit }, payload) {
    return api('notifications').delete(payload);
  },

  queryDeleteSome({ state }) {
    return api('notifications').delete(state.currentArray);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
