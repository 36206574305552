import { dispatcher, notify } from '../config';

const InspectionService = {
  list(params) {
    return dispatcher
      .loading(
        dispatcher.post('/inspections/get_list', { ...params }),
        'inspections',
      )
      .then(res => res.data);
  },

  get(id) {
    return dispatcher
      .loading(dispatcher.get('/inspections/' + id), 'inspection')
      .then(res => res.data.data);
  },

  getDocuments(id) {
    return dispatcher
      .loading(notify.get('/document/' + id), 'inspection-documents')
      .then(res => res.data);
  },

  printSticker(id, hostId) {
    return dispatcher.loading(
      dispatcher.post('/inspections/' + id + '/sticker', { hostId }),
      'print-sticker',
    );
  },

  types() {
    return dispatcher.get('/inspections/types').then(res => res.data.data);
  },

  export(params) {
    return dispatcher
      .post('/inspections/export', { ...params })
      .then(res => res.data.data);
  },
};

export default InspectionService;
