import { terminals } from '../../config';

const SchemasRepository = {
  async schema(id) {
    try {
      const result = [];
      const {
        data: { schemas },
      } = await terminals
        .loading(terminals.get(`/versions/${id}`))
        .then(res => res.data);

      for (const [key, value] of Object.entries(schemas)) {
        const schema = {
          id: null,
          name: null,
          schema: value,
          valid: true,
          key,
        };

        switch (key) {
          case 'common':
            schema.id = 1;
            schema.name = 'Общие';
            break;
          case 'inspection':
            schema.id = 2;
            schema.name = 'Осмотры';
            break;
          case 'inspectionEnd':
            schema.id = 3;
            schema.name = 'Сообщения';
            break;
          case 'devices':
            schema.id = 4;
            schema.name = 'Устроиства';
            break;
          case 'camera':
            schema.id = 5;
            schema.name = 'Камеры';
            break;
          case 'sticker':
            schema.id = 6;
            schema.name = 'Стикеры';
            break;
        }

        result.push(schema);
      }

      return result.sort((next, prev) => {
        if (next.id > prev.id) {
          return 1;
        }

        if (next.id < prev.id) {
          return -1;
        }

        return 0;
      });
    } catch (error) {
      return error;
    }
  },

  scaffold(id) {
    return terminals
      .loading(terminals.get(`/versions/${id}/scaffold`))
      .then(res => res.data);
  },
};

export default SchemasRepository;
