import { VTextField } from 'vuetify/lib';
import { wrapper, isDisabled } from './utils';

export default [
  {
    name: 'IntegerTextField',
    type: 'control',
    pattern: [
      x => x.type === 'integer',
      x => typeof x.title === 'string',
      x => typeof x.default === 'number',
    ],
    valuePath: 'default',
    component({ $h, $schema, $value, $valid, $errors, $basepath, $key }) {
      const content = $h(VTextField, {
        props: {
          value: $value(),
          label: $schema.title,
          outlined: true,
          disabled: isDisabled($schema),
          errorMessages: $errors(),
          error: $errors().length !== 0,
        },
        on: {
          input: value => {
            if ($valid(Number(value))) {
              $value(Number(value));
            }
          },
        },
      });

      return wrapper($h, content);
    },
    validate({ $schema }) {
      const rules = [
        {
          properties: {
            value: { type: 'integer' },
          },
          errorMessage: 'Разрешены только цифры.',
        },
      ];

      return rules;
    },
  },

  {
    name: 'NumberTextField',
    type: 'control',
    pattern: [
      x => x.type === 'number',
      x => typeof x.title === 'string',
      x => typeof x.default === 'number',
    ],
    valuePath: 'default',
    component({ $h, $schema, $value, $valid, $errors, $basepath, $key }) {
      const content = $h(VTextField, {
        props: {
          value: $value(),
          label: $schema.title,
          outlined: true,
          disabled: isDisabled($schema),
          errorMessages: $errors(),
          error: $errors().length !== 0,
        },
        on: {
          input: value => {
            if ($valid(Number(value))) {
              $value(Number(value));
            }
          },
        },
      });

      return wrapper($h, content);
    },
    validate({ $schema }) {
      const rules = [
        {
          properties: {
            value: { type: 'number' },
          },
          errorMessage: 'Разрешены только цифры.',
        },
      ];

      return rules;
    },
  },
];
