import AuthManager from '@frontend/auth';
import eventBus from '@/plugins/eventBus';

const instance = AuthManager({
  baseURL: process.env.VUE_APP_AUTH_BASE_URL,
  signIn: process.env.VUE_APP_AUTH_SIGNIN,
  update: process.env.VUE_APP_AUTH_UPDATE_TOKEN,
  headers: {
    'Content-Type': 'application/json',
  },
})
  .create({
    name: 'dispatcher',
    baseURL: process.env.VUE_APP_API,
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .create({
    name: 'integration',
    baseURL: process.env.VUE_APP_INTEGRATION,
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .create({
    name: 'reports',
    baseURL: process.env.VUE_APP_REPORTS_API,
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .create({
    name: 'signService',
    baseURL: process.env.VUE_APP_SIGN_SERVICE_API,
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .create({
    name: 'notifications',
    baseURL: process.env.VUE_APP_NOTIFY_API,
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .create({
    name: 'notify',
    baseURL: process.env.VUE_APP_NOTIFY_API,
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .create({
    name: 'photoArchive',
    baseURL: process.env.VUE_APP_PHOTO_ARCHIVE_API,
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .create({
    name: 'terminals',
    baseURL: process.env.VUE_APP_TERMINALS_API,
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .create({
    name: 'accounts',
    baseURL: process.env.VUE_APP_ACCOUNTS_API,
    headers: {
      'Content-Type': 'application/json',
    },
  });

const dispatcher = instance.instances('dispatcher');
const integration = instance.instances('integration');
const reports = instance.instances('reports');
const notify = instance.instances('notify');
const signService = instance.instances('signService');
const notifications = instance.instances('notifications');
const photoArchive = instance.instances('photoArchive');
const terminals = instance.instances('terminals');
const accounts = instance.instances('accounts');

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error?.response?.status === 401)
      eventBus.$emit('unauthorized', error.response.status);

    return Promise.reject(error);
  },
);

export {
  dispatcher,
  integration,
  reports,
  notify,
  signService,
  notifications,
  photoArchive,
  terminals,
  accounts,
};
export default instance;
