export default async function ({ $store, $notify, next }) {
  await $store.dispatch('BINDINGS/fetchList', { refresh: false }).catch(() => {
    $notify({
      group: 'error',
      type: 'error',
      title: `Произошла ошибка загрузки привязок. Попробуйте позже.`,
      text: '',
    });
  });
  return next();
}
