import InspectionService from './services/inspections';
import EmployeesService from './services/employees';
import OrganizationService from './services/organizations';
import HostsService from './services/hosts';
import ReportService from './services/reports';
import SignService from './services/sign-service';
import MedicService from './services/medics';
import NotificationsService from './services/admin-notifications';
import TerminalsService from './services/terminals';
import IntegrationService from './services/integration';
import AccountsService from './services/accounts';

const services = {
  inspections: InspectionService,
  employees: EmployeesService,
  organizations: OrganizationService,
  hosts: HostsService,
  reports: ReportService,
  signService: SignService,
  medics: MedicService,
  notifications: NotificationsService,
  terminals: TerminalsService,
  integration: IntegrationService,
  accounts: AccountsService,
};

export default name => {
  if (name.indexOf('/')) {
    let link = services;

    for (const namespace of name.split('/')) {
      if (link[namespace]) {
        link = link[namespace];
      } else {
        throw new Error(`Api namespace "${namespace}" not found.`);
      }
    }

    return link;
  }

  return services[name];
};
