import api from '@/api';

const state = {
  query: {
    page: 1,
    limit: 50,
  },
  total: null,
  items: [],
};

const getters = {
  query: state => state.query,

  items: state => state.items,

  page: state => state.query.page,

  terminalsCount: state => state.items.length,

  isAllViews: state => state.items.length >= parseInt(state.total),

  isEmpty: state => !state.items.length,
};

const mutations = {
  setItems(state, items) {
    state.items = items;
  },

  setTotal(state, total) {
    state.total = total;
  },

  setLimit(state, limit) {
    state.query.limit = limit;
  },

  setPage(state, page) {
    state.query.page = page;
  },

  terminalsPush(state, terminals) {
    state.items.push(...terminals);
  },
};

const actions = {
  async queryList({ commit, getters, rootGetters }) {
    const params = {
      ...getters.query,
      ...rootGetters['TERMINALS_FILTER/query'],
    };

    try {
      const { data, total } = await api('terminals/terminals').terminals(
        params,
      );

      commit('terminalsPush', data);
      commit('setTotal', total);
      return data;
    } catch (e) {
      return e;
    }
  },

  queryListScroll({ dispatch, commit, getters }) {
    if (!getters.isAllViews) {
      commit('setPage', getters.page + 1);
      return dispatch('queryList');
    }
  },

  queryListPage({ dispatch, getters }) {
    if (getters.items.length !== 0) {
      return getters.items;
    }

    return dispatch('queryList');
  },

  queryListFilter({ dispatch, commit }) {
    commit('setItems', []);
    commit('setPage', 1);
    return dispatch('queryList');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
