import api from '@/api/config';
import { auth } from '@/utils/middlewares';
import employee from '@/middleware/employee';
import employeeList from '@/middleware/employee-list';

export default {
  path: '/employee',
  name: 'employees',
  component: () => import('@/views/employees/index.vue'),
  redirect: { name: 'employees:list' },
  children: [
    {
      path: '',
      name: 'employees:list',
      component: () => import('@/views/employees/list.vue'),
      meta: {
        middleware: [auth('login', api.check), employeeList],
      },
    },
    {
      path: ':id',
      name: 'employees:card',
      component: () => import('@/views/employees/view.vue'),
      meta: {
        middleware: [auth('login', api.check), employee],
      },
    },
  ],
};
