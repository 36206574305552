<template>
  <div>
    <template v-for="modal in modals">
      <component
        :is="modal.component()"
        :key="modal.id"
        v-bind="modal.props"
        v-on="modal.listeners"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['modals']),
  },
};
</script>
