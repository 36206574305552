import Vue from 'vue';

let instance = null;

export default (function () {
  if (!instance) {
    instance = new Vue();
  }

  return instance;
})();
