import api from '@/api';

const state = {
  organizations: [],
  hostnames: [],
  orgsAvailableTypes: [],

  medicGroupTypes: [],
  medicGroups: {},

  modals: [],
};

const getters = {
  organizations: state => state.organizations,
  hostnames: state => state.hostnames,
  orgsAvailableTypes: state => state.orgsAvailableTypes,

  medicGroupTypes: state => state.medicGroupTypes,
  medicGroups: state => typeId => state.medicGroups[typeId] || [],
  medicInnerGroups: (_, getters) => [
    ...getters.medicGroups(0),
    ...getters.medicGroups(4),
    ...getters.medicGroups(1),
  ],

  modals: state => state.modals,
};

const mutations = {
  organizations: (state, value) => {
    state.organizations = value;
  },
  hostnames: (state, value) => {
    state.hostnames = value;
  },
  orgsAvailableTypes(state, value) {
    state.orgsAvailableTypes = value;
  },

  medicGroupTypes(state, { data }) {
    state.medicGroupTypes = data;
  },
  medicGroup(state, { type, name, id }) {
    state.medicGroups = {
      ...state.medicGroups,
      [type]: [...(state.medicGroups[type] || []), { name, id }],
    };
  },

  addModal(state, modalDifinition) {
    state.modals = [...state.modals, modalDifinition];
  },
  removeModal(state, id) {
    const index = state.modals.findIndex(item => item.id === id);
    state.modals = [
      ...state.modals.slice(0, index),
      ...state.modals.slice(index + 1),
    ];
  },
};

const actions = {
  async initApplication({ dispatch }) {
    // unsubscribe all callbacks before new init modules
    this.$unsubscribeAll();
    try {
      if (this.$can('PREVIEW_HOSTS')) {
        await dispatch(`fetchHostnames`);
      }

      if (this.$can('PREVIEW_ORGS')) {
        await dispatch('fetchOrganizations');
        await dispatch('fetchOrgsAvailableTypes');
      }

      if (this.$can('READ_MEDIC_GROUPS')) {
        dispatch(`fetchMedicGroupTypes`);
        dispatch(`fetchMedicGroups`);
      }
    } catch (error) {
      console.warn(error);
    }

    // fire 'init' function of each module
    if (this._modulesNamespaceMap) {
      for (const moduleName in this._modulesNamespaceMap) {
        const actionName = `${moduleName}init`;
        const actionExists = !!this._actions[actionName];
        actionExists && dispatch(actionName);
      }
    }
  },
  async fetchOrganizations({ commit, getters }) {
    commit('organizations', []);
    const data = await api('organizations').get();

    commit('organizations', data);
    return data;
  },
  async fetchHostnames({ commit, getters }) {
    commit('hostnames', []);
    const data = await api('hosts').getHostnames();

    commit('hostnames', data);
    return data;
  },
  async fetchOrgsAvailableTypes({ commit }) {
    const data = await api('inspections').types();
    commit('orgsAvailableTypes', data);

    return data;
  },
  async fetchMedicGroupTypes({ commit }) {
    const data = await api('medics').getGroupTypes();

    commit('medicGroupTypes', { data });
    return data;
  },
  // Currently medic groups used only in one place (in reports)
  // and only with id 0,1,4 (it's 'inner group' and other groups not used)
  // but it is still general information
  async fetchMedicGroups({ commit }, typeId) {
    const data = await api('medics').getGroups(typeId);

    if (typeId) {
      commit('medicGroup', { type: typeId, ...data });
    } else {
      // if we not set id of type, backend send all groups with type id
      // and we can categorize them on frontend
      data.forEach(item => commit('medicGroup', item));
    }

    return data;
  },

  getAvailableTypes({ getters }, organizations) {
    // we need to compare lentgh of selected orgs arr & restricted orgs arr cuz
    // if latter is less than what user selected we do NOT restrict available types
    if (
      organizations &&
      organizations.length <= getters.orgsAvailableTypes.length &&
      organizations.length !== 0
    ) {
      const restrictedOrgs = getters.orgsAvailableTypes.map(val => val.orgId);

      if (organizations.every(id => restrictedOrgs.includes(id))) {
        let types = getters.orgsAvailableTypes.map(v => v.types).flat();
        types = [...new Set(types)];
        return types;
      }
    }
    return null;
  },

  searchWorker(_, query) {
    // we want search user by part of name, by personnel number
    // and by user id (in case, when user id set in url )
    if (Number.isInteger(query)) {
      return api('employees')
        .get(query)
        .then(({ id, surname, name, patronymic }) => [
          { id, fullname: `${surname} ${name} ${patronymic}` },
        ]);
    }
    const searchString = query ? query.replace(/\s+/g, ' ').trim() : null;

    if (!searchString) return [];

    return api('employees').search(searchString);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
