import api from '@/api';
import { convertWorker } from '@/utils/convert/worker';

const state = {
  query: {
    orderBy: 'id',
    orderType: false,
    page: 1,
    limit: 50,
    search: '',
    // filters
    orgId: null,
    status: true,
  },
  total: 0,
  items: [],
};

const getters = {
  total: state => state.total,
  query: ({ query }) => ({
    ...query,
    orderType: query.orderType ? 'DESC' : 'ASC',
    search: query.search || null,
  }),
  limit: state => state.query.limit,
  search: state => state.query.search,
  order: state => state.query.orderBy,
  orderType: state => state.query.orderType,
  currentPage: state => state.query.page,
  totalPages: state =>
    !state.total ? 0 : Math.ceil(state.total / state.query.limit),
  isShowPagination: state => state.total / state.query.limit > 1,
  items: state => state.items.map(convertWorker),
  data: state => state.items,

  // filter logic
  orgId: state => state.query.orgId,
  status: state => state.query.status,
};

const mutations = {
  orderBy: (state, value) => (state.query.orderBy = value),
  orderType: (state, value) => (state.query.orderType = value),
  items: (state, value) => (state.items = value),
  total: (state, value) => (state.total = value),
  limit: (state, value) => (state.query.limit = value),
  currentPage: (state, value) => (state.query.page = value),
  resetCurrentPage: state => (state.query.page = 1),
  search: (state, value) => (state.query.search = value),
  orgId(state, value) {
    state.query.orgId = value;
  },
  status(state, value) {
    state.query.status = value;
  },
  resetFilters(state) {
    state.query.orgId = null;
    state.query.status = true;
    state.query.search = '';
    state.query.page = 1;
  },
};

const actions = {
  init({ commit, dispatch }) {
    ['status', 'orgId', 'limit'].forEach(mutation => {
      this.$subscribeOn(`EMPLOYEE_LIST/${mutation}`, () =>
        commit('resetCurrentPage'),
      );
    });

    // load new emploees when any of these r triggered
    [
      'resetFilters',
      'status',
      'orgId',
      'orderBy',
      'orderType',
      'limit',
      'currentPage',
    ].forEach(mutation => {
      this.$subscribeOn(`EMPLOYEE_LIST/${mutation}`, () =>
        dispatch('fetchList'),
      );
    });
  },

  async fetchList({ getters, state, commit }, refresh = true) {
    if (!refresh && getters.items.length)
      return { data: getters.items, total: state.total };

    return api('employees')
      .list(getters.query)
      .then(res => {
        commit('items', res?.data ?? []);
        commit('total', res?.total ?? 0);

        return res;
      })
      .catch(error => {
        commit('items', []);
        commit('total', 0);

        return Promise.reject(error);
      });
  },

  querySearchList({ dispatch, commit, getters }, search) {
    search = search?.trim() || '';
    const fieldWasCleared = search.length === 0 && search !== getters.search;

    if ((search.length < 2 && !fieldWasCleared) || search === getters.search)
      return;

    commit('search', search);
    commit('resetCurrentPage');

    return dispatch('fetchList');
  },

  getEmployeeById({ getters }, id) {
    return getters.data[getters.data.findIndex(item => item.id === id)];
  },

  updateListItemById({ getters, commit }, worker) {
    const index = getters.items.findIndex(item => item.id === worker.id);

    if (index >= 0) {
      const items = [
        ...getters.items.slice(0, index),
        worker,
        ...getters.items.slice(index + 1),
      ];
      commit('items', items);
    }
  },

  export(_, query) {
    return api('employees').export(query);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
