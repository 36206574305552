import api from '@/api';
import {
  convertDateToISO,
  currentDate,
  currentDateMinusThree,
} from '@/utils/helpers.js';
import { InspectionFailReasons } from '@/utils/constants.js';
import fetchListFactory from '@/utils/vuex/fetchListFactory';
import { createModule } from '@/utils/vuex/createModule';
import { convertInspection } from '@/utils/convert/inspection';
import { convertMedical } from '@/utils/convert/medical';
import { convertWorker } from '@/utils/convert/worker';

const FailReasonsIds = Object.keys(InspectionFailReasons).slice(0, -1);

const getters = {
  inspection: (_, getters) => id => {
    const item = getters.listItem(id);
    return {
      id,
      result: item.result,
      type: item.type,
      failReasons: item.failReasons,
      data: convertInspection(item),
      worker: convertWorker(item.employee),
      medical: convertMedical(item.meddata),
    };
  },

  listQuery: (state, getters, rootGetters) => {
    // if the amount of selected orgs = all orgs amount - send undefined
    const orgIds =
      (rootGetters.organizations?.length !== getters.orgIds?.length
        ? getters.orgIds
        : undefined) || undefined;

    // REVIEW: ask backend why they even need it and .. do they??
    const result = getters.results ? getters.results.includes(true) : null;

    const failReasons = getters.results.filter(item => item !== true);

    const types = getters.types?.length ? getters.types : undefined;

    return {
      page: getters.listCurrentPage,
      limit: getters.listLimit,

      // filters
      orgIds,
      employeeIds: getters.employee?.id ? [getters.employee.id] : undefined,
      hostnames: getters.hostnames || undefined,
      dateStart: convertDateToISO(getters.dateStart) || undefined,
      dateEnd:
        convertDateToISO(getters.dateEnd, '23:59', '59:999') || undefined,
      result,
      failReasons,
      types,

      // NOTE: sorting is NOT working on BACKEND side, BUT we still need
      // to send these 2 parameters to backend as HARDCODED (RIP)
      orderType: 'DESC',
      orderBy: 'id',
    };
  },
};

const actions = {
  init({ commit, rootGetters }) {
    commit(
      `orgIds`,
      rootGetters.organizations.map(({ id }) => id),
    );
  },

  export({ commit, getters }, query) {
    return api('inspections').export(query);
  },

  resetListFilters: ({ commit, rootGetters }) => {
    commit('listResetFilters');
    commit(
      `orgIds`,
      rootGetters.organizations.map(({ id }) => id),
    );
  },
};

export default createModule(
  fetchListFactory({
    fetchMethod: api('inspections').list,
    filters: {
      dateStart: currentDateMinusThree(),
      dateEnd: currentDate(),
      orgIds: null,
      hostnames: null,
      employee: null,
      results: [true, ...FailReasonsIds],
      types: null,
    },
  }),
  { getters, actions },
);
