import { validator } from '@/utils/validation';
import { required, minLength, email } from 'vuelidate/lib/validators';

export default validator({
  username: {
    required,
    email,
    minSymbolsLength: minLength(3),
  },
  password: {
    required,
    minSymbolsLength: minLength(3),
  },
});
