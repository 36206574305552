import { accounts } from '../config';

const AccountsService = {
  list(params) {
    return accounts.get('/accounts', { params }).then(res => res.data);
  },

  roles() {
    return accounts.get('/roles').then(res => res.data);
  },

  enable(id) {
    return accounts.post(`/accounts/${id}/enable`);
  },

  disable(id) {
    return accounts.post(`/accounts/${id}/disable`);
  },

  profile(params) {
    return accounts.put(`/accounts/${params.id}/profile`, params.profile);
  },

  resetPassword(id) {
    return accounts.post(`/accounts/${id}/password/reset`);
  },

  delete(id) {
    return accounts.delete(`/accounts/${id}`);
  },

  get(id) {
    return accounts.get(`/accounts/${id}`).then(res => res.data);
  },

  create(account) {
    return accounts.post('/accounts', account).then(res => res.data);
  },

  updateRole(id, roleId) {
    return accounts
      .put(`/accounts/${id}/role`, { roleId })
      .then(res => res.data);
  },

  addBindings(id, bindings) {
    return accounts
      .post('/accounts/' + id + '/bindings', { bindings })
      .then(res => res.data);
  },

  deleteBinding(id) {
    return accounts.delete('/bindings/' + id).then(res => res.data);
  },
};

export default AccountsService;
