export default async function ({ to, $store, next }) {
  const { id, isFetched } = to.params;

  try {
    if (!id && isFetched) {
      return next();
    }

    await Promise.all([
      $store.dispatch(`INSPECTION/fetch`, id),
      $store.dispatch(`INSPECTION/fetchDocuments`, id),
    ]);

    next();
  } catch (e) {
    next(e);
  }
}
