import { DateTime } from 'luxon';

/**
 * Converts date from format year-month-day to ISO 8601 date format
 * Allows to set the time and seconds separately
 * @example convertDateToISO('2005-08-09') -> '2005-08-09T00:00:00.000+04:00'
 *
 * @param {string|null} date
 * @param {string|null} time
 * @returns {DateTime}
 */
export function convertDateToISO(date, time = '00:00', ms = '00:00') {
  if (!date) return null;

  const [year, month, day] = date.split('-');
  const [hour, minute] = time.split(':');
  const [second, millisecond] = ms.split(':');

  return DateTime.local(
    Number(year),
    Number(month),
    Number(day),
    Number(hour),
    Number(minute),
    Number(second),
    Number(millisecond),
  ).toString();
}

/**
 * Returns current date with format year-month-day
 *
 * @param {string|null} date
 * @returns {string}
 */
export function currentDate() {
  return DateTime.local().toFormat('yyyy-MM-dd');
}

export function currentDateMinusThree() {
  return DateTime.local().minus({ days: 3 }).toFormat('yyyy-MM-dd');
}

/**
 * Returns current DateTime obj
 *
 * @param {string|null} date
 * @returns {DateTime}
 */
export function currentDateTime() {
  return DateTime.local();
}

/**
 * Returns DateTime object from formats:
 *  - object DateTime of luxon
 *  - object Date of js
 *  - number milliseconds of unix epoch
 *  - string date year-month-day (ISO)
 *  - string date day.month.year (Russian)
 *
 * @param {string|null} date
 * @returns {DateTime}
 */
export function parseDate(date) {
  if (!date || date instanceof DateTime) return date;
  if (date instanceof Date) return DateTime.fromJSDate(date);
  if (Number.isInteger(date)) return DateTime.fromMillis(date);

  let obj = DateTime.fromISO(date);
  if (!obj.isValid) {
    obj = DateTime.fromFormat(date, 'dd.MM.yyyy');
  }
  return obj;
}

/**
 * Compares two dates in different formats
 * For using in Array.sort(). Can sort dates with years and without
 *
 * See the list of available formats in function parseDate
 * @example ['09.06.2010', '25.05.2010', '22.06.2010'].sort(compareDates) -> ['25.05.2010', '09.06.2010', '22.06.2010']
 * @see parseDate
 *
 * @param {*} a first date (string/object)
 * @param {*} b second date (string/object)
 * @returns {number} difference between dates
 */
export function compareDates(a, b) {
  a = parseDate(a);
  b = parseDate(b);
  return a - b;
}

/**
 * Returns lowest date passed to the function
 * Removes all empty array elements (null, undefined) and invalid dates
 * See the list of available formats in function parseDate
 * @see parseDate
 *
 * @param  {...any} dates
 * @returns date
 */
export function getLowestDate(...dates) {
  return dates.sort(compareDates).filter(date => date)[0];
}

/**
 * Returns highest date passed to the function
 * Removes all empty array elements (null, undefined) and invalid dates
 * See the list of available formats in function parseDate
 * @see parseDate
 *
 * @param  {...any} dates
 * @returns date
 */
export function getHighestDate(...dates) {
  return dates
    .sort(compareDates)
    .filter(date => date)
    .slice(-1)[0];
}

/**
 * Calculates different between two dates in years
 *
 * @param {*} firstDate
 * @param {*} secondDate
 * @returns {number} difference in years
 */
export function diffYears(firstDate, secondDate) {
  firstDate = new Date(firstDate);
  secondDate = new Date(secondDate);
  // if first date is higest than second, we swap their places
  if (firstDate.valueOf() > secondDate.valueOf()) {
    secondDate = [firstDate, (firstDate = secondDate)][0];
  }
  const diff = secondDate.getFullYear() - firstDate.getFullYear();

  if (
    secondDate.getMonth() - firstDate.getMonth() < 0 ||
    secondDate.getDate() - firstDate.getDate() < 0
  ) {
    return Math.abs(diff - 1);
  }

  return Math.abs(diff);
}

/**
 * A function to take a string written in dot notation style, and use it to
 * find a nested object property inside of an object.
 * @example getProperty('a.b.c', {a: {b: {c: 'Hello!'}}}) -> 'Hello!'
 *
 * @param {string} path nested A dot notation style parameter reference (ie "urls.small")
 * @param {Object} object (optional) The object to search
 * @return the value of the property in question or undefined
 */
export function getProperty(path, object) {
  return path.split('.').reduce((acc, part) => acc && acc[part], object);
}
