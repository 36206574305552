import { auth } from '@/utils/middlewares';
import api from '@/api/config';

export default {
  path: '/reports',
  name: 'reports',
  component: () => import('@/views/reports/index.vue'),
  redirect: { name: 'reports:list' },
  meta: {
    middleware: [auth('login', api.check)],
  },
  children: [
    {
      path: '',
      name: 'reports:list',
      component: () => import('@/views/reports/list.vue'),
      meta: {
        middleware: [auth('login', api.check)],
      },
    },
    {
      path: ':id',
      name: 'reports:card',
      component: () => import('@/views/reports/view.vue'),
      meta: {
        middleware: [auth('login', api.check)],
      },
    },
  ],
};
