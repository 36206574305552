import containers from './containers';
import strings from './strings';
import numbers from './number';
import boolean from './boolean';
import lists from './lists';
import empty from './empty';
import text from './text';
import form from './form';

export default [
  ...containers,
  ...strings,
  ...numbers,
  ...boolean,
  ...lists,
  ...empty,
  ...text,
  ...form,
];
