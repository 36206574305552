import { VTextField, VSelect, VBtn, VRow, VCol, VDivider } from 'vuetify/lib';

export default {
  name: 'Cameras',

  props: {
    schema: {
      type: Object,
      default: () => ({}),
    },
  },

  created() {
    this.cameras = this.schema.default;
  },

  data() {
    return {
      cameras: [],
    };
  },

  methods: {
    onUpdate(idx, key, value) {
      if (idx && key && value) {
        this.cameras[idx][key] = value;
      }
      this.$nextTick(() => {
        this.$emit('input', [...this.cameras]);
      });
    },

    genTextField({ idx, key, value, title, description }) {
      const element = this.$createElement(VTextField, {
        props: {
          value,
          label: title,
          message: description,
          outlined: true,
        },
        on: {
          input: value => this.onUpdate(idx, key, value),
        },
      });

      return this.genWrapCameraItem(element);
    },

    genSelect({ idx, key, value, list, title, description }) {
      const element = this.$createElement(VSelect, {
        props: {
          value,
          label: title,
          items: list,
          message: description,
          outlined: true,
        },
        on: {
          change: value => this.onUpdate(idx, key, value),
        },
      });

      return this.genWrapCameraItem(element);
    },

    genWrapCameraItem(element) {
      const column = this.$createElement(
        VCol,
        {
          props: {
            cols: 12,
          },
        },
        [element],
      );

      return this.$createElement(VRow, [column]);
    },

    genWrapCamera(elements, idx) {
      const wrap = (title, remove) => {
        const col = el =>
          this.$createElement(VCol, { props: { cols: 6 } }, [el]);
        const colEnd = el =>
          this.$createElement(
            VCol,
            { props: { cols: 6 }, staticClass: 'd-flex justify-end' },
            [el],
          );
        return this.$createElement(VRow, [col(title), colEnd(remove)]);
      };

      const title = this.$createElement('h3', ['Камера ', idx + 1]);
      const removeBtn = this.$createElement(
        VBtn,
        {
          props: {
            rounded: true,
            text: true,
            small: true,
          },
          staticClass: 'blue--text',
          on: {
            click: this.onRemoveCamera(idx),
          },
        },
        ['Удалить'],
      );

      return this.$createElement('div', { key: idx }, [
        wrap(title, removeBtn),
        this.$createElement(VDivider, { staticClass: 'mb-5' }),
        elements,
      ]);
    },

    genBtn() {
      return this.$createElement(
        VBtn,
        {
          props: {
            color: 'blue',
          },
          staticClass: 'white--text',
          on: {
            click: () => this.onAddCamera(),
          },
        },
        ['Добавить камеру'],
      );
    },

    genContent() {
      return this.cameras.map((item, idx) => {
        const forms = [];

        for (const [key, value] of Object.entries(item)) {
          let element = null;

          const title = this.schema.items.properties[key].title || '';
          const description =
            this.schema.items.properties[key].description || '';
          const defaultValue =
            value || this.schema.items.properties[key].default || null;

          switch (key) {
            case 'label':
              element = this.genTextField({
                idx,
                key,
                value,
                title,
                description,
              });
              break;
            case 'name':
              element = this.genSelect({
                idx,
                key,
                value: defaultValue,
                list: this.schema.items.properties[key].enum,
                title,
                description,
              });
              break;
            case 'in':
              element = this.genSelect({
                idx,
                key,
                value: defaultValue,
                list: this.schema.items.properties[key].enum,
                title,
                description,
              });
              break;
            default:
              break;
          }

          if (element) {
            forms.push(element);
          }
        }

        return this.genWrapCamera(forms, idx);
      });
    },

    onAddCamera() {
      this.cameras.push({
        label: 'XXXXXXX',
        name: 'frontCamera',
        in: '/dev/video-int',
        fmt: 'video4linux2',
      });
      this.onUpdate();
    },

    onRemoveCamera(idx) {
      return () => {
        this.cameras.splice(idx, 1);
        this.onUpdate();
      };
    },
  },

  render(h) {
    const content = this.genContent();

    return h('div', { staticClass: 'mb-5' }, [
      ...content,
      this.genWrapCameraItem(this.genBtn()),
    ]);
  },
};
